import { defineMessages } from "react-intl.macro";
import { defaultMenuRenderer } from "react-select";

const Messages = defineMessages({
  //m: {  id: "",  defaultMessage: "" },
  //   done: { id: "", defaultMessage: "" },

  change_pwd_to_cont: {
    id: "change_pwd_to_cont",
    defaultMessage: "Please change your password to continue",
  },
  cp_updated_pt: {
    id: "cp_updated_pt",
    defaultMessage: "Care Plan has been updated for the Patient.",
  },
  invalidate_sessions_conf: {
    id: "invalidate_sessions_conf",
    defaultMessage: "All session have been invalidated.",
  },
  invalidate_sessions_warn: {
    id: "invalidate_sessions_warn",
    defaultMessage:
      "Are you certain that you want to invalidate all sessions for this user? Please note that this action will log the user out from all the devices they have previously logged in to.",
  },
  invalidate_sessions: {
    id: "invalidate_sessions",
    defaultMessage: "Invalidate Sessions",
  },
  signup_alternative: {
    id: "signup_alternative",
    defaultMessage:
      "Alternatively, you can use a different email or phone number to create a new account.",
  },
  forgot_pwd_su: {
    id: "forgot_pwd_su",
    defaultMessage:
      "If you've forgotten your password, you can reset it by following the steps from <forgot>here</forgot>.",
  },
  forgot_userId: {
    id: "forgot_userId",
    defaultMessage:
      "In case you have forgotten, the username for the account is: {loginUserId}",
  },
  wait_account: {
    id: "wait_account",
    defaultMessage: "Wait...You already have an account",
  },
  started_surveys: {
    id: "started_surveys",
    defaultMessage: "Started Surveys",
  },
  pdf_downloaded: {
    id: "pdf_downloaded",
    defaultMessage:
      "PDF has been downloaded. Please check Downloads on your device.",
  },
  pdf_downloading: {
    id: "pdf_downloading",
    defaultMessage: "PDF is downloading...",
  },
  hide_profile: {
    id: "hide_profile",
    defaultMessage: "Hide Profile",
  },
  configure_crit: {
    id: "configure_crit",
    defaultMessage: "Configure Criteria",
  },
  exclude_pts_match: {
    id: "exclude_pts_match",
    defaultMessage: "Exclude patients matching",
  },
  visible_bw: {
    id: "visible_bw",
    defaultMessage: "Visible Between (Optional)",
  },
  completed_surveys: {
    id: "completed_surveys",
    defaultMessage: "Completed Surveys",
  },
  incomplete_surveys: {
    id: "incomplete_surveys",
    defaultMessage: "Incomplete Surveys",
  },
  no_events: {
    id: "no_events",
    defaultMessage: "There are no events in this range.",
  },
  appt_link_error: {
    id: "appt_link_error",
    defaultMessage: "Please set name and template for all links.",
  },
  schedule_for: { id: "schedule_for", defaultMessage: "Schedule for: {name}" },
  text_update: { id: "text_update", defaultMessage: "Text update" },
  audio_recording: { id: "audio_recording", defaultMessage: "Audio recording" },
  videos: { id: "videos", defaultMessage: "Videos" },
  vids_thumbs_error: {
    id: "vids_thumbs_error",
    defaultMessage:
      "Different number of videos and thumbnails - Videos ({noOfFiles}) Thumbs ({noOfThumbs})",
  },
  current_files: { id: "current_files", defaultMessage: "Current Files" },
  pl_att_files_w_type: {
    id: "pl_att_files_w_type",
    defaultMessage: "Please attach {subType} file(s).",
  },
  content: { id: "content", defaultMessage: "Content" },
  media_type: { id: "media_type", defaultMessage: "Media type" },
  upload_progress: { id: "upload_progress", defaultMessage: "Upload progress" },
  error: { id: "error", defaultMessage: "Error" },
  before_mins: {
    id: "before_mins",
    defaultMessage: "Before Minutes: {reminderBeforeMinutes}",
  },
  send_days_before: {
    id: "send_days_before",
    defaultMessage:
      "Send {fixedNoOfDaysBefore} days before at {fixedTimeToSend}",
  },
  appt_templ_comments_tt: {
    id: "appt_templ_comments_tt",
    defaultMessage:
      "Comments will also be used as event description on the calendar.",
  },
  appt_templ_desc_tt: {
    id: "appt_templ_desc_tt",
    defaultMessage:
      "Appointment description will be shown as event name on the calendar.",
  },
  incl_video_call: {
    id: "incl_video_call",
    defaultMessage: "Include Video Calling?",
  },
  appt_templ_name_tt: {
    id: "appt_templ_name_tt",
    defaultMessage:
      "Identifies an Appointment template. It's not used in the appointment data.",
  },
  url: { id: "url", defaultMessage: "URL" },
  link_name: { id: "link_name", defaultMessage: "Link Name" },
  se_date_reqd: {
    id: "se_date_reqd",
    defaultMessage: "Start and End Date are required",
  },
  comments_w_c: { id: "comments_w_c", defaultMessage: "Comments: {comments}" },
  deleted_pp: {
    id: "deleted_pp",
    defaultMessage: "This is a deleted/inactivated patient profile.",
  },
  cal_assignee: { id: "cal_assignee", defaultMessage: "Assignee" },
  cal_schedules: {
    id: "cal_schedules",
    defaultMessage: "Schedules",
  },
  complete: {
    id: "complete",
    defaultMessage: "Complete",
  },
  consolidated_report_uc: {
    id: "consolidated_report_uc",
    defaultMessage: "CONSOLIDATED REPORT",
  },
  weekly_login_activity: {
    id: "weekly_login_activity",
    defaultMessage: "Weekly Login Activity",
  },
  monthly_login_activity: {
    id: "monthly_login_activity",
    defaultMessage: "Monthly Login Activity",
  },
  xNoteAdded: {
    id: "xNoteAdded",
    defaultMessage: "Note has been added to the other facility.",
  },
  acct_ready: {
    id: "acct_ready",
    defaultMessage: "Your account on myPatientSpace is ready! {loginUserId}",
  },
  username_dot_er: {
    id: "username_dot_er",
    defaultMessage: "Username can not have more than one period(.).",
  },
  username_len_er: {
    id: "username_len_er",
    defaultMessage: "Username should have at least 5 characters.",
  },
  username_emp_er: {
    id: "username_emp_er",
    defaultMessage: "Username can not be empty.",
  },
  username_sp_er: {
    id: "username_sp_er",
    defaultMessage: "Username can not have spaces.",
  },
  terms_pp_w_link: {
    id: "terms_pp_w_link",
    defaultMessage:
      "I have read and accept the <terms>terms & conditions</terms> and <pp>privacy policy</pp> for {name}.",
  },
  cp_library: { id: "cp_library", defaultMessage: "Copy Library - {name}" },
  delete_library: { id: "delete_library", defaultMessage: "Delete Library" },
  draft: { id: "draft", defaultMessage: "Draft" },
  pl_select_temp_sch: {
    id: "pl_select_temp_sch",
    defaultMessage: "Please select a template and a schedule",
  },
  pl_select_link: {
    id: "pl_select_link",
    defaultMessage: "Please select a link",
  },
  pl_select_slots: {
    id: "pl_select_slots",
    defaultMessage: "Please select slot(s)",
  },
  on_text: { id: "on_text", defaultMessage: "on" },
  six_weeks_years_pp: {
    id: "six_weeks_years_pp",
    defaultMessage:
      "6 Weeks, 6 Months, 1 Year, 2 Years and 5 Years Pre and Post",
  },
  lt_week: { id: "lt_week", defaultMessage: "< A Week" },
  lt_two_weeks: { id: "lt_two_weeks", defaultMessage: "< 2 Weeks" },
  lt_month: { id: "lt_month", defaultMessage: "< 1 Month" },
  lt_two_months: { id: "lt_two_months", defaultMessage: "< 2 Months" },
  gt_two_months: { id: "gt_two_months", defaultMessage: "> Two Months" },
  pre_post: { id: "pre_post", defaultMessage: "Pre and Post" },
  pre: { id: "pre", defaultMessage: "Pre" },
  post: { id: "post", defaultMessage: "Post" },
  created_by_on: {
    id: "created_by_on",
    defaultMessage: "Created by {name} on {date}",
  },
  new_password: { id: "new_password", defaultMessage: "New Password" },
  reenter_new_pwd: {
    id: "reenter_new_pwd",
    defaultMessage: "Re-enter New Password",
  },
  set_new_password: {
    id: "set_new_password",
    defaultMessage: "Please set new password.",
  },
  confirm_new_pwd_err: {
    id: "confirm_new_pwd_err",
    defaultMessage: "Confirm password does not match with the new password",
  },
  set_confirm_password: {
    id: "set_confirm_password",
    defaultMessage: "Please set confirm new password",
  },
  set_auto_creds: {
    id: "set_auto_creds",
    defaultMessage: "Set Auto Credentials",
  },
  set_creds_manually: {
    id: "set_creds_manually",
    defaultMessage: "Set Credentials Manually",
  },
  sal_ms: { id: "sal_ms", defaultMessage: "Ms" },
  sal_miss: { id: "sal_miss", defaultMessage: "Miss" },
  sal_mrs: { id: "sal_mrs", defaultMessage: "Mrs" },
  sal_mr: { id: "sal_mr", defaultMessage: "Mr" },
  sal_master: { id: "sal_master", defaultMessage: "Master" },
  sal_fr: { id: "sal_fr", defaultMessage: "Father (Fr)" },
  sal_rev: { id: "sal_rev", defaultMessage: "Reverend (Rev)" },
  sal_dr: { id: "sal_dr", defaultMessage: "Doctor (Dr)" },
  sal_atty: { id: "sal_atty", defaultMessage: "Attorney (Atty)" },
  phone_or_email: {
    id: "phone_or_email",
    defaultMessage: "Phone number or Email",
  },
  credentials_reset_act: {
    id: "credentials_reset_act",
    defaultMessage: "Patient has been activated and credentials have been set.",
  },
  activate_patient: {
    id: "activate_patient",
    defaultMessage: "Activate Patient",
  },
  activate: {
    id: "activate",
    defaultMessage: "Activate",
  },
  free_slots_to_cont: {
    id: "free_slots_to_cont",
    defaultMessage: "free slots (green) to continue booking",
  },
  select_dt_time_for_appt: {
    id: "select_dt_time_for_appt",
    defaultMessage: "Please select date and time for the appointment",
  },
  send_link_message_to_all_q: {
    id: "send_link_message_to_all_q",
    defaultMessage:
      "Are you sure you want to send the link message to all participants?",
  },
  please_provide_msgs: {
    id: " please_provide_msgs",
    defaultMessage: "Please provide messages.",
  },
  send_to_all_parts: {
    id: "send_to_all_parts",
    defaultMessage: "Send to all participants",
  },
  enter_msg_with_link: {
    id: "enter_msg_with_link",
    defaultMessage: "Enter a message with the LINK",
  },
  appts_created_w_desc: {
    id: "appts_created_w_desc",
    defaultMessage: "The following appointments have been created for {desc}.",
  },
  class: { id: "class", defaultMessage: "Class" },
  join: { id: "join", defaultMessage: "Join" },
  class_on: { id: "class_on", defaultMessage: "Class on" },
  select_class_w_green: {
    id: "select_class_w_green",
    defaultMessage: "Select a class (green) to join.",
  },
  ph_for_appt: {
    id: "ph_for_appt",
    defaultMessage: "Please enter your Phone number for the appointment.",
  },
  appt_conf_w_details: {
    id: "appt_conf_w_details",
    defaultMessage: "We have sent you an email with the appointment details.",
  },
  with_w_col: { id: "with_w_col", defaultMessage: "with:" },
  please_sl_staff: {
    id: "please_sl_staff",
    defaultMessage: "Please select a staff",
  },
  select_slot_w_green: {
    id: "select_slot_w_green",
    defaultMessage: "Select a free slot (green) to continue booking",
  },
  appt_w_staff: {
    id: "appt_w_staff",
    defaultMessage: "{dateTime} with {staffName}",
  },
  join_class_on: {
    id: "join_class_on",
    defaultMessage: "You are about to join class {description} on:",
  },
  joined_class_text: {
    id: " joined_class_text",
    defaultMessage:
      "You have joined class {description} on {slot} with {staffName}.",
  },
  class_conf: {
    id: "class_conf",
    defaultMessage:
      "We have sent you an email with the class details. Thank you for using myPatientSpace.",
  },
  cancel_booking: { id: "cancel_booking", defaultMessage: "Cancel Booking" },
  scheduled_for: { id: "scheduled_for", defaultMessage: "scheduled for" },
  alt_sel_link_here: {
    id: "alt_sel_link_here",
    defaultMessage: "Alternatively, select one of the links below",
  },
  with_text: { id: "with_text", defaultMessage: "with" },
  scheduling_appt_for: {
    id: "scheduling_appt_for",
    defaultMessage: "Scheduling an Appointment for:",
  },
  select_add_patient: {
    id: "select_add_patient",
    defaultMessage: "Please select or add a patient",
  },
  or_w_hyphen: { id: "or_w_hyphen", defaultMessage: "- or -" },
  new_adhoc_pt_for_appt: {
    id: "new_adhoc_pt_for_appt",
    defaultMessage: "New Ad-hoc Patient for Appointment",
  },
  contact_dets: { id: "contact_dets", defaultMessage: "Contact Details" },
  new_contact_dets: {
    id: "new_contact_dets",
    defaultMessage: "New contact details",
  },
  how_to_contact_me: {
    id: "how_to_contact_me",
    defaultMessage: "How to Contact Me",
  },
  contact_in_app: {
    id: "contact_in_app",
    defaultMessage: "Contact details registered in the app",
  },
  close_current_appt: {
    id: "close_current_appt",
    defaultMessage: "Close the current appointment?",
  },
  published_links: { id: "published_links", defaultMessage: "Published Links" },
  appt_temp_sch: {
    id: "appt_temp_sch",
    defaultMessage: "Templates and Schedules",
  },
  sel_appt_temp_sch: {
    id: "sel_appt_temp_sch",
    defaultMessage: "Select Appointment Template and Schedule",
  },
  series_cancelled: {
    id: "series_cancelled",
    defaultMessage: "All Appointments in the series have been cancelled.",
  },
  no_upcoming_appts: {
    id: "no_upcoming_appts",
    defaultMessage: "There are no upcoming appointments.",
  },
  appt_series_w_desc: {
    id: "appt_series_w_desc",
    defaultMessage: "Appointment Series for {description}",
  },
  view_appt: { id: "view_appt", defaultMessage: "View Appointment" },
  cancel_appt_series: {
    id: "cancel_appt_series",
    defaultMessage: "Cancel Appointment Series",
  },
  to_cancel_appt_series: {
    id: "to_cancel_appt_series",
    defaultMessage: "Are you sure you want to cancel the appointment series?",
  },
  screening_result: {
    id: "screening_result",
    defaultMessage: "Screening Result",
  },
  join_video_call: { id: "join_video_call", defaultMessage: "Join Video Call" },
  video_call_title: {
    id: "video_call_title",
    defaultMessage: "Start your video call",
  },
  send_appt_link: {
    id: "send_appt_link",
    defaultMessage: "Send Appointment Link",
  },
  book_again: { id: "book_again", defaultMessage: "Book Again" },
  free_sp_one: { id: "free_sp_one", defaultMessage: "1 free space available" },
  free_sp_count: {
    id: "free_sp_count",
    defaultMessage: "{count} free spaces available",
  },
  appt_ba_help: {
    id: "appt_ba_help",
    defaultMessage:
      "Starts creating a new appointment using details from this appointment but for different slots selected.",
  },
  appt_reb_help: {
    id: "appt_reb_help",
    defaultMessage:
      "Mark this appointment fulfilled and starts creating the new appointment.",
  },
  view_series: { id: "view_series", defaultMessage: "View Series" },
  pts_upcoming_appts: {
    id: "pts_upcoming_appts",
    defaultMessage: "Patient's Upcoming Appointments",
  },
  select_slots_sch: {
    id: "select_slots_sch",
    defaultMessage: "Select slots from the schedule",
  },
  or_enter_dt: { id: "or_enter_dt", defaultMessage: "Or Enter Date & Time" },
  you_are_here: { id: "you_are_here", defaultMessage: "You are here!" },
  upcoming_appointments: {
    id: "upcoming_appointments",
    defaultMessage: "Upcoming Appointments",
  },
  daily: { id: "daily", defaultMessage: "Daily" },
  weekly: { id: "weekly", defaultMessage: "Weekly" },
  monthly: { id: "monthly", defaultMessage: "Monthly" },
  end_date: { id: "end_date", defaultMessage: "End Date" },
  every: { id: "every", defaultMessage: "Every" },
  recurringq: { id: "recurringq", defaultMessage: "Recurring?" },
  recurring_err: {
    id: "recurring_err",
    defaultMessage: "Repeat, Every, and End Date are required",
  },
  use_at_as_appt_st: {
    id: "use_at_as_appt_st",
    defaultMessage: "Use Arrival Time As Appointment Start Time?",
  },
  arrive_before_im: {
    id: "arrive_before_im",
    defaultMessage: "Arrive Before (in Minutes)",
  },
  slot_ct_error: {
    id: "slot_ct_error",
    defaultMessage: "Please create at least one slot",
  },
  hour: { id: "hour", defaultMessage: "Hour" },
  min: { id: "min", defaultMessage: "Min" },
  responses_w_label: {
    id: "responses_w_label",
    defaultMessage: "Responses - {label}",
  },
  ref_range_count: {
    id: "ref_range_count",
    defaultMessage: "Reference Range Count",
  },
  response_rate_by_tw: {
    id: "response_rate_by_tw",
    defaultMessage: "Response Rate By Time Window",
  },
  prev: {
    id: "prev",
    defaultMessage: "Prev",
  },
  send_to_patient: { id: "send_to_patient", defaultMessage: "Send To Patient" },
  one_doc: { id: "one_doc", defaultMessage: "1 Document" },
  docs_w_count: { id: "docs_w_count", defaultMessage: "{count} Documents" },
  doc_w_name: {
    id: "doc_w_name",
    defaultMessage: "Document - {name}",
  },
  doc_del_conf: {
    id: "doc_del_conf",
    defaultMessage:
      "Are you sure you want to delete the document {name}? It will be permanently deleted from the system and can not be reverted.",
  },
  send_doc_pt: {
    id: "send_doc_pt",
    defaultMessage: "Send Document to Patient",
  },
  edit_document: { id: "edit_document", defaultMessage: "Edit Document" },
  delete_document: { id: "delete_document", defaultMessage: "Delete Document" },
  filter_by_topic: { id: "filter_by_topic", defaultMessage: "Filter By Topic" },
  all_fields_reqd: {
    id: "all_fields_reqd",
    defaultMessage: "Please provide values for all fields.",
  },
  invite_ptsq: {
    id: "invite_ptsq",
    defaultMessage: "Invite Patients?",
  },
  care_team: { id: "care_team", defaultMessage: "Care Team" },
  import_patients: { id: "import_patients", defaultMessage: "Import Patients" },
  patients_w_count: {
    id: "patients_w_count",
    defaultMessage: "{count} Patients",
  },
  one_patient: { id: "one_patient", defaultMessage: "1 Patient" },
  dau: { id: "dau", defaultMessage: "Daily (DAU)" },
  wau: { id: "wau", defaultMessage: "Weekly (WAU)" },
  mau: { id: "mau", defaultMessage: "Monthly (MAU)" },
  user_activity: {
    id: "user_activity",
    defaultMessage: "User Activity",
  },
  discontinued: {
    id: "discontinued",
    defaultMessage: "Discontinued",
  },
  clone_template: {
    id: "clone_template",
    defaultMessage: "Clone Template",
  },
  one_app_reasons: {
    id: "one_app_reasons",
    defaultMessage: "Please select one of the reasons if applicable",
  },
  archive_confirm: {
    id: "archive_confirm",
    defaultMessage: "Archive Patient: Please Confirm",
  },
  invitation_expired: {
    id: "invitation_expired",
    defaultMessage: "Invitation Expired",
  },
  deleted_uc: { id: "deleted_uc", defaultMessage: "DELETED" },
  invited: { id: "invited", defaultMessage: "Invited" },
  added: { id: "added", defaultMessage: "Added" },
  dropout: { id: "dropout", defaultMessage: "Dropout" },
  sure_send_messages_to: {
    id: "sure_send_messages_to",
    defaultMessage:
      "Are you sure you want to send the messages to {noOfPatients} patients?",
  },
  charge_items_uc: {
    id: "charge_items_uc",
    defaultMessage: "PURCHASE HISTORY",
  },
  color: {
    id: "color",
    defaultMessage: "Color",
  },
  date_of_consent: { id: "date_of_consent", defaultMessage: "Date Of Consent" },
  appt_desc_w_date: {
    id: "appt_desc_w_date",
    defaultMessage: "{description} on {date}",
  },
  dp_consent: {
    id: "dp_consent",
    defaultMessage: "See Data Processing Consent",
  },
  configure: { id: "configure", defaultMessage: "Configure" },
  wc_activate: {
    id: "wc_activate",
    defaultMessage:
      "Welcome back! You have just joined {facilityName}. You already have an account with myPatientSpace. Your account is pending activation. Please activate your account by clicking on the link that has been sent to your email/phone.",
  },
  decide_appt_time_later: {
    id: "decide_appt_time_later",
    defaultMessage: "Decide Appointment Time Later",
  },
  staff_added_ct: {
    id: "staff_added_ct",
    defaultMessage:
      "Staff has been added and a care team created for the staff. Please check Doctor Teams tab.",
  },
  no_teams: {
    id: "no_teams",
    defaultMessage: "There are no teams at the moment.",
  },
  doctor_teams: {
    id: "doctor_teams",
    defaultMessage: "Doctor Teams",
  },
  support_teams: {
    id: "support_teams",
    defaultMessage: "Support Teams",
  },
  a_create_doc_team: {
    id: "a_create_doc_team",
    defaultMessage: "Also create a team for this doctor?",
  },
  doctor_visibility: {
    id: "doctor_visibility",
    defaultMessage: "Apply restricted patient visibility",
  },
  referral_date: {
    id: "referral_date",
    defaultMessage: "Date Referred",
  },
  enroll_date: {
    id: "enroll_date",
    defaultMessage: "Date Enrolled",
  },
  first_insulin: {
    id: "first_insulin",
    defaultMessage: "First Time Insulin",
  },
  weight: {
    id: "weight",
    defaultMessage: "Weight",
  },
  hba1c: {
    id: "hba1c",
    defaultMessage: "HbA1c",
  },
  blood_glucose: {
    id: "blood_glucose",
    defaultMessage: "Blood Glucose",
  },
  preferred_language: {
    id: "preferred_language",
    defaultMessage: "Preferred Language",
  },
  diabetes_type: {
    id: "diabetes_type",
    defaultMessage: "Diabetes Type",
  },
  can_pt_book: {
    id: "can_pt_book",
    defaultMessage: "Allow Self Booking By Patients?",
  },
  appts_created: {
    id: "appts_created",
    defaultMessage: "Appointments have been created.",
  },
  reschedule_appt_g: {
    id: "reschedule_appt_g",
    defaultMessage: "Reschedule Appointment - General",
  },
  slots_per_appt: {
    id: "slots_per_appt",
    defaultMessage: "Slots Per Appointment",
  },
  rebook_appointment: {
    id: "rebook_appointment",
    defaultMessage: "Rebook Appointment",
  },
  appointment_on_d: {
    id: "appointment_on_d",
    defaultMessage: "Appointment on {dateTime}",
  },
  edit_details: {
    id: "edit_details",
    defaultMessage: "Edit Details",
  },
  rebook: {
    id: "rebook",
    defaultMessage: "Rebook",
  },
  reschedule_appointment: {
    id: "reschedule_appointment",
    defaultMessage: "Reschedule Appointment",
  },
  reschedule: {
    id: "reschedule",
    defaultMessage: "Reschedule",
  },
  change_appt_time: {
    id: "change_appt_time",
    defaultMessage: "Change Appointment Time",
  },
  current_appt_time: {
    id: "current_appt_time",
    defaultMessage: "Current Appointment Time",
  },
  new_appt_time: {
    id: "new_appt_time",
    defaultMessage: "New Appointment Time",
  },
  correct_anss: { id: "correct_anss", defaultMessage: "Correct Answers:" },
  correct_ans: { id: "correct_ans", defaultMessage: "Correct Answer:" },
  correct: { id: "correct", defaultMessage: "Correct" },
  incorrect: { id: "incorrect", defaultMessage: "Incorrect" },
  ca_na_reqd: {
    id: "ca_na_reqd",
    defaultMessage: "Current assignee and new assignee are required",
  },
  req_acc: { id: "req_acc", defaultMessage: "Both Requested and Accepted" },
  enter_valid_date: {
    id: "enter_valid_date",
    defaultMessage: "Enter a valid date",
  },
  completed_sm: { id: "completed_sm", defaultMessage: "completed" },
  xOfy: { id: "xOfy", defaultMessage: "{completed} of {required}" },
  org_deleted: {
    id: "org_deleted",
    defaultMessage: "Organization has been deleted.",
  },
  error_org_delete: {
    id: "error_org_delete",
    defaultMessage: "Error deleting the organization.",
  },
  delete_org_warning: {
    id: "delete_org_warning",
    defaultMessage:
      "Are you sure you want to delete organization {orgName}? This can not be reverted.",
  },
  delete_org: {
    id: "delete_org",
    defaultMessage: "Delete Organization",
  },
  organizations: { id: "organizations", defaultMessage: "Organizations" },
  this_is_you: { id: "this_is_you", defaultMessage: "This is You!" },
  reset_password: { id: "reset_password", defaultMessage: "Reset Password" },
  role_reqd: { id: "role_reqd", defaultMessage: "Role is Required" },
  add_new_staff: { id: "add_new_staff", defaultMessage: "Add New Staff" },
  staff_restored: {
    id: "staff_restored",
    defaultMessage: "Staff has been restored.",
  },
  password_reset_w_name: {
    id: "password_reset_w_name",
    defaultMessage:
      "Password has been reset for {name}. An Email/SMS will be sent with the new credentials",
  },
  tasks_by_topics_w_count: {
    id: "tasks_by_topics_w_count",
    defaultMessage: "Tasks By Topics - {count}",
  },
  tasks_by_templates_w_count: {
    id: "tasks_by_templates_w_count",
    defaultMessage: "Tasks By Templates - {count}",
  },
  override_role_privileges: {
    id: "override_role_privileges",
    defaultMessage: "Override Role Privileges",
  },
  role_privileges_msg: {
    id: "role_privileges_msg",
    defaultMessage:
      "If you have selected a Role above, selection here would override the privileges from it.",
  },
  allow_channel_publishing: {
    id: "allow_channel_publishing",
    defaultMessage: "Allow Channel Publishing?",
  },
  external_api: { id: "external_api", defaultMessage: "External API" },
  external_id: { id: "external_id", defaultMessage: "External Id" },
  role: { id: "role", defaultMessage: "Role" },
  super_user_q: { id: "super_user_q", defaultMessage: "Super User?" },
  publisher_staff_q: {
    id: "publisher_staff_q",
    defaultMessage: "Publisher Staff?",
  },
  privileges: { id: "privileges", defaultMessage: "Privileges" },
  default_visibility_afs: {
    id: "default_visibility_afs",
    defaultMessage: "Default Visibility On Created Artifacts",
  },
  attributes_filter_pt: {
    id: "attributes_filter_pt",
    defaultMessage: "Attributes to Filter Patient",
  },
  pt_detail_attributes: {
    id: "pt_detail_attributes",
    defaultMessage: "Accessible Patient Detail Attributes",
  },
  staff_no_usage: {
    id: "staff_no_usage",
    defaultMessage: "No usage data found for this staff.",
  },
  want_delete_name: {
    id: "want_delete_name",
    defaultMessage: "Do you want to delete {name}?",
  },
  want_remove_name: {
    id: "want_remove_name",
    defaultMessage: "Do you want to remove {name} from Organization?",
  },
  confirm_deleting_type: {
    id: "confirm_deleting_type",
    defaultMessage: "Confirm Deleting {type}",
  },
  confirm_removing_type: {
    id: "confirm_removing_type",
    defaultMessage: "Confirm Removing {type}",
  },
  rp_w_name: { id: "rp_w_name", defaultMessage: "Reset Password - {name}" },
  include_inactive_staff: {
    id: "include_inactive_staff",
    defaultMessage: "Include Inactive Staff",
  },
  total_staff: { id: "total_staff", defaultMessage: "{total} Staff" },
  no_type_added: {
    id: "no_type_added",
    defaultMessage: "No {type} added yet.",
  },
  edit_org: { id: "edit_org", defaultMessage: "Edit Organization" },
  org_ct_info: {
    id: "org_ct_info",
    defaultMessage:
      "There is a default care team created for this organization. You can add/remove more care teams based on requirements.",
  },
  city_town: { id: "city_town", defaultMessage: "City/Town" },
  update: { id: "update", defaultMessage: "Update" },
  logo: { id: "logo", defaultMessage: "Logo" },
  contact_info: { id: "contact_info", defaultMessage: "Contact Info" },
  org_created: {
    id: "org_created",
    defaultMessage: "Organization has been created.",
  },
  org_create_err: {
    id: "org_create_err",
    defaultMessage: "Error creating the organization.",
  },
  create_org: { id: "create_org", defaultMessage: "Create an Organization" },
  no_orgs: {
    id: "no_orgs",
    defaultMessage: "There are no organizations at the moment.",
  },
  staff_no_orgs: {
    id: "staff_no_orgs",
    defaultMessage: "You are not part of any organizations at the moment.",
  },
  error_removing_staff: {
    id: "error_removing_staff",
    defaultMessage: "Error removing the staff from the organization.",
  },
  staff_removed_org: {
    id: "staff_removed_org",
    defaultMessage: "Staff has been removed from the Organization.",
  },
  add_existing_staff: {
    id: "add_existing_staff",
    defaultMessage: "Add Existing Staff",
  },
  sure_to_reset_creds_staff: {
    id: "sure_to_reset_creds_staff",
    defaultMessage:
      "Are you sure you want to reset the password for the staff?",
  },
  reset_creds_warning_msg_staff: {
    id: "reset_creds_warning_msg_staff",
    defaultMessage:
      "Clicking on the submit button will reset the password for the staff",
  },
  staff_added: { id: "staff_added", defaultMessage: "Staff has been added." },
  error_adding_staff: {
    id: "error_adding_staff",
    defaultMessage: "Error adding the staff to the organization.",
  },
  org_updated: {
    id: "org_updated",
    defaultMessage: "Organization has been updated.",
  },
  error_org_update: {
    id: "error_org_update",
    defaultMessage: "Error updating the organization.",
  },
  staff_saved: {
    id: "staff_saved",
    defaultMessage: "Staff details have been updated.",
  },
  nav_patients: { id: "nav_patients", defaultMessage: "Patients" },
  terms_privacy_for: { id: "terms_privacy_for", defaultMessage: "for {text}" },
  st_hm_treatment: { id: "st_hm_treatment", defaultMessage: "Treatment" },
  last: { id: "last", defaultMessage: "Last" },
  first: { id: "first", defaultMessage: "First" },
  others: { id: "others", defaultMessage: "Others" },
  submit_assessments_w_name_mrn: {
    id: "submit_assessments_w_name_mrn",
    defaultMessage: "Submit Assessments for {patientName}. MRN: {mrn}",
  },
  patients_added: { id: "patients_added", defaultMessage: "Patients Added" },
  patients_onboarded: {
    id: "patients_onboarded",
    defaultMessage: "Patients Onboarded",
  },
  active_monthly_avg: {
    id: "active_monthly_avg",
    defaultMessage: "Active Monthly (Avg)",
  },
  hi_w_name: { id: "hi_w_name", defaultMessage: "Hi, {name}!" },
  summary_today: {
    id: "summary_today",
    defaultMessage: "Here is your summary for today",
  },
  view_patients: { id: "view_patients", defaultMessage: "View Patients" },
  info_res: {
    id: "info_res",
    defaultMessage: "Information and Other Resources",
  },
  home: { id: "home", defaultMessage: "Home" },
  staff_ip_config_error: {
    id: "staff_ip_config_error",
    defaultMessage:
      "Information Panel for Staff is not configured. Please contact your system administrator.",
  },
  mfa_adv_1: {
    id: "mfa_adv_1",
    defaultMessage:
      "Enabling Two Factor Authentication will make your account more secure. We strongly recommend you to enable this.",
  },
  mfa_adv_2: {
    id: "mfa_adv_2",
    defaultMessage:
      "Please note that opting out from Two Factor Authentication will make your account less secure. We recommend against opting out 2FA.",
  },
  opt_in_mfa: { id: "opt_in_mfa", defaultMessage: " Opt In 2FA" },
  opt_out_mfa: { id: "opt_out_mfa", defaultMessage: "Opt Out 2FA" },
  setup_2fa: { id: "setup_2fa", defaultMessage: "Setup 2FA" },
  mfa_enter_otp: {
    id: "mfa_enter_otp",
    defaultMessage: "Enter OTP that has been sent to your Email/Phone.",
  },
  otp: { id: "otp", defaultMessage: "OTP" },
  mfa_opt_in_msg: {
    id: "mfa_opt_in_msg",
    defaultMessage:
      "Well done! You have taken a very best step to secure your account. From now on, you will be asked to enter a OTP everytime you login to myPatientSpace.",
  },
  mfa_opt_out_msg: {
    id: "mfa_opt_out_msg",
    defaultMessage:
      "We have registered your preference. You can setup 2FA any time from your profile. You can access your profile by clicking on your icon/picture at the top right corner.",
  },
  mfa_select_def_msg: {
    id: "mfa_select_def_msg",
    defaultMessage: "Do you want to opt for SMS/Email based 2FA OTP for login?",
  },
  mfa_opt_out: { id: "mfa_opt_out", defaultMessage: "No, Opt Out" },
  mfa_opt_in: { id: "mfa_opt_in", defaultMessage: "Yes, Opt In" },
  space_changed: {
    id: "space_changed",
    defaultMessage: "Space has been changed. Please login to access it.",
  },
  is_eq: { id: "is_eq", defaultMessage: "is equal to" },
  is_bw: { id: "is_bw", defaultMessage: "is between" },
  is_lt: { id: "is_lt", defaultMessage: "is less than" },
  is_lte: { id: "is_lte", defaultMessage: "is less than or equal to" },
  is_gt: { id: "is_gt", defaultMessage: "is greater than" },
  is_gte: { id: "is_gte", defaultMessage: "is greater than or equal to" },
  is_et: { id: "is_et", defaultMessage: "is empty" },
  is_not_et: { id: "is_not_et", defaultMessage: "is not empty" },
  notes_for_staff: { id: "notes_for_staff", defaultMessage: "Notes for Staff" },
  messages_to_patients: {
    id: "messages_to_patients",
    defaultMessage: "Messages and Updates to Patients",
  },
  summary_uc: { id: "summary_uc", defaultMessage: "SUMMARY" },
  sure_to_reopen_appt: {
    id: "sure_to_reopen_appt",
    defaultMessage: "Are you sure you want to reopen the appointment?",
  },
  next_mrn: { id: "next_mrn", defaultMessage: "Next MRN" },
  screening_warning: {
    id: "screening_warning",
    defaultMessage:
      "This screening is meant to be performed within {value} hours of the appointment time.",
  },
  call_status: { id: "call_status", defaultMessage: "Call Status" },
  resubmit: { id: "resubmit", defaultMessage: "Resubmit" },
  cancel_resubmit: { id: "cancel_resubmit", defaultMessage: "Cancel Resubmit" },
  covid_screening: {
    id: "covid_screening",
    defaultMessage: "Screening",
  },
  date_time: { id: "date_time", defaultMessage: "Date/Time" },
  save_close: { id: "save_close", defaultMessage: "Save & Close" },
  appt_screening_res: {
    id: "appt_screening_res",
    defaultMessage: "Appointment Screening Result",
  },
  submit_participants: {
    id: "submit_participants",
    defaultMessage: "Submit Participants",
  },
  clinic_sts_risk: { id: "clinic_sts_risk", defaultMessage: "Done - Risk" },
  clinic_sts_ok: { id: "clinic_sts_ok", defaultMessage: "Done - OK" },
  clinic_sts_nr: { id: "clinic_sts_nr", defaultMessage: "Not Reachable" },
  clinic_sts_na: { id: "clinic_sts_na", defaultMessage: "Not Available" },
  clinic_sts_np: { id: "clinic_sts_np", defaultMessage: "No Phone" },
  clinic_sts_resch: {
    id: "clinic_sts_resch",
    defaultMessage: "Already rescheduled on PAS",
  },
  clinic_sts_lm: { id: "clinic_sts_lm", defaultMessage: "Left Message" },
  clinic_sts_noans: { id: "clinic_sts_noans", defaultMessage: "No Answer" },
  clinic_sts_pt_reqc: {
    id: "clinic_sts_pt_reqc",
    defaultMessage: "Patient Req. Cancel",
  },
  clinic_sts_pt_ca: {
    id: "clinic_sts_pt_ca",
    defaultMessage: "Patient Prev. Cancelled",
  },
  clinic_sts_pending: { id: "clinic_sts_pending", defaultMessage: "Pending" },
  scheduled: { id: "scheduled", defaultMessage: "Scheduled" },
  no: { id: "no", defaultMessage: "No" },
  asap_uc: { id: "asap_uc", defaultMessage: "ASAP" },
  urgent_uc: { id: "urgent_uc", defaultMessage: "URGENT" },
  normal_uc: { id: "normal_uc", defaultMessage: "NORMAL" },
  low_uc: { id: "low_uc", defaultMessage: "LOW" },
  stat_uc: { id: "stat_uc", defaultMessage: "STAT" },
  requested_uc: { id: "requested_uc", defaultMessage: "REQUESTED" },
  accepted_uc: { id: "accepted_uc", defaultMessage: "ACCEPTED" },
  completed_uc: { id: "completed_uc", defaultMessage: "COMPLETED" },
  current_password: {
    id: "current_password",
    defaultMessage: "Current Password",
  },
  set_current_password: {
    id: "set_current_password.",
    defaultMessage: "Please set current password.",
  },
  pwd_changed: {
    id: "pwd_changed",
    defaultMessage: "Password has been changed.",
  },
  change_password: { id: "change_password", defaultMessage: "Change Password" },
  requested: { id: "requested", defaultMessage: "Requested" },
  accepted: { id: "accepted", defaultMessage: "Accepted" },
  count: { id: "count", defaultMessage: "Count" },
  assignee_tasks_due: {
    id: "assignee_tasks_due",
    defaultMessage: "Assignee and Tasks Due",
  },
  assignee_task_status: {
    id: "assignee_task_status",
    defaultMessage: "Assignee and Task Status",
  },
  signup_conf_thank_u: {
    id: "signup_conf_thank_u",
    defaultMessage:
      "Thank you for signing up with myPatientSpace. Please check your email or SMS and click on the link to complete the Signup process.",
  },
  signup_conf_app: {
    id: "signup_conf_app",
    defaultMessage:
      "You can close this window and return to myPatientSpace by tapping DONE on top right",
  },
  signup_conf_ins: {
    id: "signup_conf_ins",
    defaultMessage:
      "Please download the application on your mobile device. After installing the app, Sign In using your username and password.",
  },
  send_message_to: {
    id: "send_message_to",
    defaultMessage: "Send Message to {name}",
  },
  no_info_panel: {
    id: "no_info_panel",
    defaultMessage: "Info panel is not configured",
  },
  uploaded_files: { id: "uploaded_files", defaultMessage: "Uploaded Files" },
  assets_desc: {
    id: "assets_desc",
    defaultMessage:
      "Once file is uploaded to public CDN, you can use the mentioned URL as the image source. Accepts jpg, jpeg, png, gif, mp3, mp4, avi, aac",
  },

  appt_booked_for_slot: {
    id: "appt_booked_for_slot",
    defaultMessage: "An appointment has been booked for {slot}",
  },
  sent_appt_email: {
    id: "sent_appt_email",
    defaultMessage: "We have sent you an email with the appointment details.",
  },
  book_now: { id: "book_now", defaultMessage: "Book Now" },
  book_appt_w_name: {
    id: "book_appt_w_name",
    defaultMessage: "Book Appointment - {name}",
  },
  review_booking: { id: "review_booking", defaultMessage: "Review Booking" },
  select_a_staff: {
    id: "select_a_staff",
    defaultMessage: "Please select a staff",
  },
  select_green_slot: {
    id: "select_green_slot",
    defaultMessage: "Select a free slot (green) to continue booking",
  },
  select_green_slot_w_count: {
    id: "select_green_slot_w_count",
    defaultMessage: "Select {count} free slots (green) to continue booking",
  },
  with: { id: "with", defaultMessage: "with" },

  select_or_add_pt: {
    id: "select_or_add_pt",
    defaultMessage: "Please select or add a patient",
  },
  for_patient: { id: "for_patient", defaultMessage: "for: {patientName}" },
  new_ad_hoc_patient: {
    id: "new_ad_hoc_patient",
    defaultMessage: "New Ad-hoc Patient for Appointment",
  },
  contact_details: { id: "contact_details", defaultMessage: "Contact Details" },
  contact_details_in_app: {
    id: "contact_details_in_app",
    defaultMessage: "Contact details registered in the app",
  },
  new_contact_details: {
    id: "new_contact_details",
    defaultMessage: "New contact details",
  },

  no_n_slots: {
    id: "no_n_slots",
    defaultMessage: "{noOfSlots} consecutive free slots are not available.",
  },
  share: { id: "share", defaultMessage: "Share" },
  select_a_schedule_view_class: {
    id: "select_a_schedule_view_class",
    defaultMessage: "Select a schedule to view the classes/rooms",
  },
  select_schedule: { id: "select_schedule", defaultMessage: "Select Schedule" },
  logins: { id: "logins", defaultMessage: "Logins" },
  survey_submission: {
    id: "survey_submission",
    defaultMessage: "Survey Submission",
  },
  no_joints_affected: {
    id: "no_joints_affected",
    defaultMessage: "No joints swollen or tender",
  },
  humanoid_no_positions: {
    id: "humanoid_no_positions",
    defaultMessage:
      "You have not selected any positions in the humanoid image.",
  },
  score_w_score: { id: "score_w_score", defaultMessage: "Score: {score}" },
  question_not_answered: {
    id: "question_not_answered.",
    defaultMessage: "This question was not answered.",
  },
  total_value_w_score: {
    id: "total_value_w_score",
    defaultMessage: "Total Score: {totalScore}",
  },
  total_value_w_value: {
    id: "total_value_w_value",
    defaultMessage: "Total Value: {totalValue}",
  },
  field_mandatory: {
    id: "field_mandatory",
    defaultMessage: "This field is mandatory",
  },
  father: { id: "father", defaultMessage: "Father" },
  mother: { id: "mother", defaultMessage: "Mother" },
  wife: { id: "wife", defaultMessage: "Wife" },
  husband: { id: "husband", defaultMessage: "Husband" },
  daughter: { id: "daughter", defaultMessage: "Daughter" },
  son: { id: "son", defaultMessage: "Son" },
  brother: { id: "brother", defaultMessage: "Brother" },
  sister: { id: "sister", defaultMessage: "Sister" },
  friend: { id: "friend", defaultMessage: "Friend" },
  room: { id: "room", defaultMessage: "Room" },
  floor: { id: "floor", defaultMessage: "Floor" },
  address_line_1: { id: "address_line_1", defaultMessage: "Address Line 1" },
  address_line_2: { id: "address_line_2", defaultMessage: "Address Line 2" },
  city: { id: "city", defaultMessage: "City" },
  district: { id: "district", defaultMessage: "District" },
  state: { id: "state", defaultMessage: "State" },
  postal_code: { id: "postal_code", defaultMessage: "Postal code" },
  bio: { id: "bio", defaultMessage: "Bio" },
  gender_optional: {
    id: "gender_optional",
    defaultMessage: "Gender (Optional)",
  },
  set_gender: { id: "set_gender.", defaultMessage: "Please set gender." },
  set_dob: { id: "set_dob", defaultMessage: "Please set Birth date." },
  dob_optional: {
    id: "dob_optional",
    defaultMessage: "Birth date (Optional)",
  },
  task_priority_w: {
    id: "task_priority_w",
    defaultMessage: "Task Priority: {priority}",
  },
  task_status_w: {
    id: "task_status_w",
    defaultMessage: "Task Status: {status}",
  },
  not_set: { id: "not_set", defaultMessage: "Not Set" },
  prefix_optional: {
    id: "prefix_optional",
    defaultMessage: "Prefix (Optional)",
  },
  first_name: { id: "first_name", defaultMessage: "First Name" },
  set_given_name: {
    id: "set_given_name",
    defaultMessage: "Please set a given name",
  },
  last_name: { id: "last_name", defaultMessage: "Last Name" },
  set_last_name: {
    id: "set_last_name",
    defaultMessage: "Please set a last name.",
  },
  phone_optional: { id: "phone_optional", defaultMessage: "Phone (Optional)" },
  phone_email_optional: {
    id: "phone_email_optional",
    defaultMessage: "Phone (Phone or email optional)",
  },
  email_optional: { id: "email_optional", defaultMessage: "Email (Optional)" },
  good_morning: { id: "good_morning", defaultMessage: "Good Morning" },
  good_afternoon: { id: "good_afternoon", defaultMessage: "Good Afternoon" },
  good_evening: { id: "good_evening", defaultMessage: "Good Evening" },
  class1_med_device: {
    id: "class1_med_device",
    defaultMessage: "myPatientSpace is a Class 1 Medical Device",
  },
  new_room: { id: "new_room", defaultMessage: "New Room" },
  new_class: { id: "new_class", defaultMessage: "New Class" },
  add_p_error1: {
    id: "add_p_error1",
    defaultMessage: "Please fill in valid values for all mandatory fields.",
  },
  add_p_error2: {
    id: "add_p_error2",
    defaultMessage: "Email or Phone number is mandatory.",
  },
  add_p_error3: {
    id: "add_p_error3",
    defaultMessage: "Email and Confirm Email should be the same.",
  },
  add_p_error4: {
    id: "add_p_error4",
    defaultMessage: "Phone and Confirm Phone should be the same.",
  },
  add_p_config_error: {
    id: "add_p_config_error",
    defaultMessage:
      "Add Patient page is not configured for the selected patient type. Please contact administrator.",
  },
  no_invite: { id: "no_invite", defaultMessage: "Don't send invite?" },
  msg_no_onboarding_steps: {
    id: "msg_no_onboarding_steps",
    defaultMessage:
      "If you set username and password invited person will be activated automatically, without any onboarding steps.",
  },
  set_text: { id: "set_text", defaultMessage: "Set" },
  set_un_pwd_wc: {
    id: "set_un_pwd_wc",
    defaultMessage: "SET USERNAME & PASSWORD",
  },
  pt_text: { id: "pt_text", defaultMessage: "Patient Type: {text}" },
  add_pt_type_helper: {
    id: "add_pt_type_helper",
    defaultMessage: "This will change and reset the form given below.",
  },
  print: { id: "print", defaultMessage: "Print" },
  report_duration: { id: "report_duration", defaultMessage: "Report Duration" },
  last_one_month: { id: "last_one_month", defaultMessage: "Last One Month" },
  last_two_months: { id: "last_two_months", defaultMessage: "Last Two Months" },
  last_three_months: {
    id: "last_three_months",
    defaultMessage: "Last Three Months",
  },
  last_six_months: { id: "last_six_months", defaultMessage: "Last Six Months" },
  last_year: { id: "last_year", defaultMessage: "Last Year" },
  search: { id: "search", defaultMessage: "Search" },
  link: { id: "link", defaultMessage: "Link" },
  view_template: { id: "view_template", defaultMessage: "View Template" },
  view_schedule: { id: "view_schedule", defaultMessage: "View Schedule" },
  no_pub_schedules: {
    id: "no_pub_schedules",
    defaultMessage: "There are no published schedules available",
  },
  book: { id: "book", defaultMessage: "Book" },
  group_cr: { id: "group_cr", defaultMessage: "Group Classes/Rooms" },
  published_schedules: {
    id: "published_schedules",
    defaultMessage: "Published Schedules",
  },
  share_link: { id: "share_link", defaultMessage: "Share Link" },
  new_ad_hoc_booking: {
    id: "new_ad_hoc_booking",
    defaultMessage: "New Ad-hoc Booking",
  },
  csv_file: { id: "csv_file", defaultMessage: "CSV file" },
  view_pub_sch: {
    id: "view_pub_sch",
    defaultMessage: "View Published Schedules",
  },
  upcoming_events: { id: "upcoming_events", defaultMessage: "Upcoming Events" },
  view_all: { id: "view_all", defaultMessage: "View All" },
  view_details: { id: "view_details", defaultMessage: "View Details" },

  pt_sch_info: {
    id: "pt_sch_info",
    defaultMessage:
      "This Patient type will be assigned to all the patients who are added as part of clinic uploads that use this schedule.",
  },
  sch_invite_pts: {
    id: "sch_invite_pts",
    defaultMessage: "Invite/Re-Invite Patients Uploaded?",
  },
  sch_type: { id: "sch_type", defaultMessage: "Schedule Type" },
  one_one_sch: { id: "one_one_sch", defaultMessage: "One-to-One Schedules" },
  group_class: { id: "group_class", defaultMessage: "Group Class" },
  group_classes: { id: "group_classes", defaultMessage: "Group Classes" },
  conf_rooms: { id: "conf_rooms", defaultMessage: "Conference Rooms" },
  ce_placeholder: {
    id: "ce_placeholder",
    defaultMessage:
      "Type your text content here. Select text to apply formatting.",
  },
  hours_in_adv: { id: "hours_in_adv", defaultMessage: "Hours in advance" },
  booking_weekend: {
    id: "booking_weekend",
    defaultMessage: "Allow Booking On Weekend",
  },
  location_lib: {
    id: "location_lib",
    defaultMessage: "Location Helper Library",
  },
  require_consent: {
    id: "require_consent",
    defaultMessage: "Require explicit consent?",
  },
  consent_text: { id: "consent_text", defaultMessage: "Consent Text" },
  needs_action: { id: "needs_action", defaultMessage: "Needs Action?" },
  ct_for_appts: {
    id: "ct_for_appts",
    defaultMessage: "Care Team For Appointments",
  },
  emails_clo_notif: {
    id: "emails_clo_notif",
    defaultMessage: "Emails for Closure Notification",
  },
  comms_emails: { id: "", defaultMessage: "Comma separated emails" },
  view_inp_slots: {
    id: "view_inp_slots",
    defaultMessage: "View In-progress Slots",
  },
  picked_n_slots: {
    id: "picked_n_slots",
    defaultMessage: "Picked {count} Slots",
  },
  one_space: { id: "one_space", defaultMessage: "1 free space" },
  count_free_spaces: {
    id: "count_free_spaces",
    defaultMessage: "{count} free spaces",
  },
  one_slot: { id: "one_slot", defaultMessage: "1 Slot" },
  count_slots: { id: "count_slots", defaultMessage: "{count} Slots" },

  edit_sch_w_name: { id: "done", defaultMessage: "Edit Schedule - {name}" },
  create_slots_w_name: {
    id: "create_slots_w_name",
    defaultMessage: "Create Slots  - {name}",
  },
  create_gc_w_name: {
    id: "create_gc_w_name",
    defaultMessage: "Create Group Classes  - {name}",
  },
  delete_sch_w_name: {
    id: "delete_sch_w_name",
    defaultMessage: "Delete Schedule - {name}",
  },
  create_links_w_name: {
    id: "create_links_w_name",
    defaultMessage: "Create Links - {name}",
  },
  delete_slots_w_name: {
    id: "delete_slots_w_name",
    defaultMessage: "Delete Slots  - {name}",
  },
  slots_warning: {
    id: "slots_warning",
    defaultMessage:
      "Please set slot duration in the schedule info to be able to create slots.",
  },
  create_links: { id: "create_links", defaultMessage: "Create Links" },
  change_schedule_info: {
    id: "change_schedule_info",
    defaultMessage: "Change Schedule Info",
  },
  create_slots: { id: "create_slots", defaultMessage: "Create Slots" },
  copy_link: { id: "copy_link", defaultMessage: "Copy Link" },
  custom_data: { id: "custom_data", defaultMessage: "Custom Data" },
  temp_msg_cancel_pt: {
    id: "temp_msg_cancel_pt",
    defaultMessage: "Cancel Appointment - Patient",
  },
  temp_msg_cancel_staff: {
    id: "temp_msg_cancel_staff",
    defaultMessage: "Cancel Appointment - Staff",
  },
  general: { id: "general", defaultMessage: "General" },
  reschedule_appt_pt: {
    id: "reschedule_appt_pt",
    defaultMessage: "Reschedule Appointment - Patient",
  },
  reschedule_appt_staff: {
    id: "reschedule_appt_staff",
    defaultMessage: "Reschedule Appointment - Staff",
  },
  congratulations: {
    id: "congratulations",
    defaultMessage: "Congratulations!",
  },
  setup_success: {
    id: "setup_success",
    defaultMessage: "You have successfully setup your account.",
  },
  username_is: {
    id: "username_is",
    defaultMessage: "Your username is: <bold>{userName}</bold>",
  },

  download_install: {
    id: "download_install",
    defaultMessage: "Download & Install the app on your mobile device.",
  },
  signin_w_un_pwd1: {
    id: "signin_w_un_pwd1",
    defaultMessage:
      "Sign-in with username: {userName}, and the password you just setup.",
  },
  signin_w_un_pwd2: {
    id: "signin_w_un_pwd2",
    defaultMessage: "Sign-in with your username and the password.",
  },
  ty_service: {
    id: "ty_service",
    defaultMessage: "Thank you for using this service.",
  },
  step_2_creds: {
    id: "step_2_creds",
    defaultMessage: "Step 2 of 3: Your Credentials",
  },
  error_un_pwd: {
    id: "error_un_pwd",
    defaultMessage: "Error with username and password set.",
  },
  received_as: { id: "received_as", defaultMessage: "Received As" },
  enter_to_ai: {
    id: "enter_to_ai",
    defaultMessage: "Enter the following details to accept invitation.",
  },
  enter_e_p: {
    id: "enter_e_p",
    defaultMessage:
      "Enter your email or phone with country code to accept invitation.",
  },
  enter_email_inv: {
    id: "enter_email_inv",
    defaultMessage: "Enter your email to accept invitation.",
  },
  enter_cc_ph: {
    id: "enter_cc_ph",
    defaultMessage:
      "Select country and enter your phone number to accept invitation.",
  },
  invited_to_join_w_fn: {
    id: "invited_to_join_w_fn",
    defaultMessage:
      "You’ve been invited to join {facilityName} on myPatientSpace",
  },
  invited_to_join: {
    id: "invited_to_join",
    defaultMessage: "You’ve been invited to join myPatientSpace",
  },
  step_1_accept: {
    id: "step_1_accept",
    defaultMessage: "Step 1 of 3: Accept Invitation",
  },
  install: { id: "install", defaultMessage: "Install" },
  register: { id: "register", defaultMessage: "Register" },
  mps_acct_setup_for: {
    id: "mps_acct_setup_for",
    defaultMessage: "myPatientSpace Account Setup for {facilityName}",
  },
  mps_acct_setup: {
    id: "mps_acct_setup",
    defaultMessage: "myPatientSpace Account Setup",
  },
  view_patient: { id: "view_patient", defaultMessage: "View Patient" },
  pt_screening_appt: {
    id: "pt_screening_appt",
    defaultMessage: "Patient Screening - Appointment",
  },
  no_appointments: { id: "no_appointments", defaultMessage: "No Appointments" },
  screening: {
    id: "screening",
    defaultMessage: "Screening",
  },
  contact: {
    id: "contact",
    defaultMessage: "Contact",
  },
  mrn: { id: "mrn", defaultMessage: "MRN" },
  time: { id: "time", defaultMessage: "Time" },
  screened_ok: { id: "screened_ok", defaultMessage: "Screened - OK" },
  screened_risk: { id: "screened_risk", defaultMessage: "Screened - Risk" },
  screening_pending: {
    id: "screening_pending",
    defaultMessage: "Screening Pending",
  },
  ok: { id: "ok", defaultMessage: "Ok" },
  risk: { id: "risk", defaultMessage: "Risk" },
  screened_by_staff: {
    id: "screened_by_staff",
    defaultMessage: "Screened By Staff",
  },
  self_screen: { id: "self_screen", defaultMessage: "Self Screen" },
  screened: { id: "screened", defaultMessage: "Screened" },
  view_report: { id: "view_report", defaultMessage: "View Report" },
  assigned_to_w_col: {
    id: "assigned_to_w_col",
    defaultMessage: "Assigned to:",
  },
  consultant_w_col: {
    id: "consultant_w_col",
    defaultMessage: "Consultant:",
  },
  call_action_req: {
    id: "call_action_req",
    defaultMessage: "Call Action Required",
  },
  speciality_w_col: { id: "speciality_w_col", defaultMessage: "Speciality:" },
  call_patients: { id: "call_patients", defaultMessage: "Call Patients" },
  report: { id: "report", defaultMessage: "Report" },
  valid_json_file: {
    id: "valid_json_file",
    defaultMessage: "Please set a valid file with proper JSON content.",
  },
  file: { id: "file", defaultMessage: "File" },
  library_w_count: {
    id: "library_w_count",
    defaultMessage: "Library ({count})",
  },
  topics_w_count: {
    id: "topics_w_count",
    defaultMessage: "Topics ({count})",
  },
  topic: { id: "topic", defaultMessage: "Topic" },
  file_type: { id: "file_type", defaultMessage: "File Type" },
  featured_content: {
    id: "featured_content",
    defaultMessage: "Featured Content",
  },
  sort_order: { id: "sort_order", defaultMessage: "Sort Order" },
  created: { id: "created", defaultMessage: "Created" },
  modified: { id: "modified", defaultMessage: "Modified" },
  actions: { id: "actions", defaultMessage: "Actions" },
  appointment: { id: "appointment", defaultMessage: "Appointment" },
  open: { id: "open", defaultMessage: "Open" },
  cal_more: { id: "cal_more", defaultMessage: "+{total} more" },
  template_param_ht: {
    id: "template_param_ht",
    defaultMessage: "Set status text and add templateParams using '_'",
  },
  start_time: { id: "start_time", defaultMessage: "Start Time" },
  end_time: { id: "end_time", defaultMessage: "End Time" },
  select_care_team: {
    id: "select_care_team",
    defaultMessage: "Select Care Team",
  },
  select_care_teams: {
    id: "select_care_teams",
    defaultMessage: "Select Care Teams",
  },
  submit_survey_q: {
    id: "submit_survey_q",
    defaultMessage: "Confirm Submission",
  },
  sure_submit_survey: {
    id: "sure_submit_survey",
    defaultMessage: "Ready to submit your responses?",
  },
  submit_assessment: {
    id: "submit_assessment",
    defaultMessage: "Submit Assessment",
  },
  value_bw_1and6: {
    id: "value_bw_1and6",
    defaultMessage: "Please enter a value between 1 and 6.",
  },
  value_bw_100and800: {
    id: "value_bw_100and800",
    defaultMessage: "Please enter a value between 100 and 800.",
  },
  answer_mandatory_qs: {
    id: "answer_mandatory_qs",
    defaultMessage: "Valid Answer is required for all mandatory questions.",
  },
  gc_error: {
    id: "gc_error",
    defaultMessage:
      "No of Patients exceeds the maximum number of allowed patients for this class",
  },
  set_valid_participants: {
    id: "set_valid_participants",
    defaultMessage: "Please set valid participants.",
  },
  facility_admin: { id: "facility_admin", defaultMessage: "Facility Admin" },
  arrival_time: { id: "arrival_time", defaultMessage: "Arrival Time" },
  max_part_allowed: {
    id: "max_part_allowed",
    defaultMessage: "Maximum Number of Patient Participants Allowed",
  },
  include_video_call: {
    id: "include_video_call",
    defaultMessage: "Include Video Call?",
  },
  data: { id: "data", defaultMessage: "Data" },
  comments_info: {
    id: "comments_info",
    defaultMessage:
      "Comments will also be used as event description on the calendar.",
  },
  advanced_options: {
    id: "advanced_options",
    defaultMessage: "Advanced Options",
  },
  gc_appointment: {
    id: "gc_appointment",
    defaultMessage: "Group Class Appointment",
  },
  gc_info: {
    id: "gc_info",
    defaultMessage:
      "Group Class Group call appointment has been created. You can share the below link with your admin.",
  },
  no_schedule: { id: "no_schedule", defaultMessage: "No Schedule" },
  enter_valid_number: {
    id: "enter_valid_number",
    defaultMessage: "Please enter a valid number",
  },
  free_slots_info: {
    id: "free_slots_info",
    defaultMessage:
      "This needs {count} consecutive free slots. When you select the first slot, it will automatically select other slots based on availability.",
  },
  selected_slot: {
    id: "selected_slot",
    defaultMessage: "Selected Slot: {slot}",
  },
  select_a_schedule: {
    id: "select_a_schedule",
    defaultMessage: "Please select a schedule",
  },
  select_a_slot: {
    id: "select_a_slot",
    defaultMessage: "Please select a slot",
  },
  select_free_slot: {
    id: "select_free_slot",
    defaultMessage: "Please select a free slot",
  },
  new_booking_for: {
    id: "new_booking_for",
    defaultMessage: "New Booking for {name}",
  },
  select_a_template: {
    id: "select_a_template",
    defaultMessage: "Please select a template",
  },
  one_free_space: { id: "one_f_space", defaultMessage: " | 1 free space" },
  n_free_spaces: {
    id: "n_free_spaces",
    defaultMessage: " | {count} free spaces",
  },
  slot: { id: "slot", defaultMessage: "Slot" },
  default_text: { id: "default_text", defaultMessage: "default" },
  assign_cp_conf: {
    id: "assign_cp_conf",
    defaultMessage: "Are you sure you want to {op} the care plan?",
  },
  archive_warning: {
    id: "archive_warning",
    defaultMessage:
      "WARNING: Archiving Patient Data cannot be undone.  None of this patient’s data will be visible to staff in ANY listing view.  Patient will no longer be able to use the myPatientSpace App or on the Web (they will be logged out).  This action is irreversible, and we urge extreme caution, recovery will NOT be possible.",
  },
  sure_to_archive: {
    id: "sure_to_archive",
    defaultMessage:
      "FINAL WARNING:  This action is irreversible.  Are you sure? Press Confirm to continue to Archive all patient data.",
  },
  yes_deactivate: { id: "yes_deactivate", defaultMessage: "Yes, De-Activate" },
  archive_patient: {
    id: "archive_patient",
    defaultMessage: "Archive Patient Data?",
  },
  assessment_deleted: {
    id: "assessment_deleted",
    defaultMessage: "Assessment has been deleted.",
  },
  delete_assessment: {
    id: "delete_assessment",
    defaultMessage: "Delete Assessment",
  },
  to_delete_sr: {
    id: "to_delete_sr",
    defaultMessage:
      "Are you sure you want to delete the assessment? It will be permanently deleted from the system and can not be reverted.",
  },
  reopen_task: {
    id: "reopen_task",
    defaultMessage: "Reopen Task?",
  },
  view_complete_space: {
    id: "view_complete_space",
    defaultMessage: "View Complete Space",
  },
  view_profile: {
    id: "view_profile",
    defaultMessage: "View Profile",
  },
  consolidated_report: {
    id: "consolidated_report",
    defaultMessage: "Consolidated Report",
  },
  send_message: {
    id: "send_message",
    defaultMessage: "Send Message",
  },
  username_already_taken: {
    id: "username_already_taken",
    defaultMessage: "Mentioned Username is already taken.",
  },
  set_valid_un: {
    id: "set_valid_un",
    defaultMessage: "Please set valid Username.",
  },
  email_phone_are: {
    id: "email_phone_are",
    defaultMessage: "Your Email address and Phone number are",
  },
  email_is: { id: "email_is", defaultMessage: "Your Email address is" },
  phone_no_is: { id: "phone_no_is", defaultMessage: "Your Phone number is" },
  email_ph_associated_already: {
    id: "email_ph_associated_already",
    defaultMessage:
      "{prefix} already associated with an account. You can <login>login</login> to your existing account or <continue>continue</continue> to submit your details to add as another space under the existing account.",
  },
  con_ph_no: { id: "con_ph_no", defaultMessage: "Confirm Phone Number" },
  login_creds: { id: "login_creds", defaultMessage: "Login Credentials" },
  set_un_pwd: {
    id: "set_un_pwd",
    defaultMessage: "Set your Login Username and Password",
  },
  email_or_phone: {
    id: "email_or_phone",
    defaultMessage: "Please enter email or mobile phone number.",
  },

  confirm_email: { id: "confirm_email", defaultMessage: "Confirm Email" },
  country: { id: "country", defaultMessage: "Country" },
  confirm_email_req: {
    id: "confirm_email_req",
    defaultMessage: "Confirm Email is required.",
  },
  confirm_phone_req: {
    id: "confirm_phone_req",
    defaultMessage: "Confirm Phone is required.",
  },
  should_past_date: {
    id: "should_past_date",
    defaultMessage: "{field} should be a past date",
  },
  should_future_date: {
    id: "should_future_date",
    defaultMessage: "{field} should be a future date",
  },
  date_bw: {
    id: "date_bw",
    defaultMessage: "Enter a date between {fromDate} and {toDate}",
  },
  number_bw: {
    id: "number_bw",
    defaultMessage: "Provide a valid number that is between {from} and {to}",
  },
  provide_valid_email: {
    id: "provide_valid_email",
    defaultMessage: "Please provide a valid email",
  },
  provide_valid_phone: {
    id: "provide_valid_phone",
    defaultMessage: "Please provide a valid phone with country code",
  },
  un_taken: {
    id: "un_taken",
    defaultMessage: "This Username is already taken.",
  },
  with_an_acount_alr: {
    id: "with_an_acount_alr",
    defaultMessage: "This {field} is already associated with an account.",
  },
  not_valid_field: {
    id: "not_valid_field",
    defaultMessage: "This {field} is not valid",
  },
  field_used: {
    id: "field_used",
    defaultMessage: "This {field} is already used.",
  },
  wc_back_associated: {
    id: "wc_back_associated",
    defaultMessage:
      "Welcome back! You have just joined {facilityName}. You already have an account with myPatientSpace associated with {emailAndPhone}.",
  },
  click_if_dnk_pwd: {
    id: "click_if_dnk_pwd",
    defaultMessage: "If you don't remember the password, click",
  },

  var_is_required: {
    id: "var_is_required",
    defaultMessage: "{title} is required.",
  },
  phone_number_cc: { id: "phone_number_cc", defaultMessage: "Phone Number" },
  privacy_policy_cc: {
    id: "privacy_policy_cc",
    defaultMessage: "Privacy Policy",
  },
  terms_conds_cc: {
    id: "terms_conds_cc",
    defaultMessage: "Terms and Conditions",
  },
  mps_terms_conds: {
    id: "mps_terms_conds",
    defaultMessage: "myPatientSpace Terms and Conditions",
  },

  verification_failed: {
    id: "verification_failed",
    defaultMessage: "Verification Failed",
  },
  for_text: { id: "for_text", defaultMessage: "for" },
  and: { id: "and", defaultMessage: "and" },
  have_read_accept: {
    id: "have_read_accept",
    defaultMessage: "I have read and accept the",
  },
  terms_conds: { id: "terms_conds", defaultMessage: "terms & conditions" },
  privacy_policy: { id: "privacy_policy", defaultMessage: "privacy policy" },
  creating_acc: { id: "creating_acc", defaultMessage: "creating this account" },
  keyword: { id: "keyword", defaultMessage: "Keyword" },
  country_code: { id: "country_code", defaultMessage: "Country code" },
  set_valid_id: { id: "set_valid_id", defaultMessage: "Please set valid ID." },
  id_uc: { id: "id_uc", defaultMessage: "ID" },
  send_instructions_reset_pwd: {
    id: "send_instructions_reset_pwd",
    defaultMessage:
      "Please enter your registered {contact}. We will send you instructions to reset your password.",
  },
  forgot_password_wo_q: {
    id: "forgot_password_wo_q",
    defaultMessage: "Forgot password",
  },

  reassign_tasks: { id: "reassign_tasks", defaultMessage: "Reassign Tasks" },
  current_assignee: {
    id: "current_assignee",
    defaultMessage: "Current Assignee",
  },
  tasks_reassigned: {
    id: "tasks_reassigned",
    defaultMessage: "{count} Tasks have been reassigned.",
  },
  new_assignee: { id: "new_assignee", defaultMessage: "New Assignee" },
  add_cancelled: { id: "add_cancelled", defaultMessage: "Add Cancelled?" },
  cancelled_only: {
    id: "cancelled_only",
    defaultMessage: "Show Cancelled Only?",
  },
  free_slots: { id: "free_slots", defaultMessage: "Free Slots" },
  both: { id: "both", defaultMessage: "Both" },
  schedules: {
    id: "schedules",
    defaultMessage: "Schedules",
  },
  active_staff: {
    id: "active_staff",
    defaultMessage: "Active Staff",
  },
  inactive_staff: {
    id: "inactive_staff",
    defaultMessage: "Inactive Staff",
  },
  gender: {
    id: "gender",
    defaultMessage: "Gender",
  },
  staff_age: {
    id: "staff_age",
    defaultMessage: "Staff Age",
  },
  no_results_found: {
    id: "no_results_found",
    defaultMessage: "No results found",
  },
  unknown: { id: "unknown", defaultMessage: "Unknown" },
  no_data_found: { id: "no_data_found", defaultMessage: "No data found." },
  invitation_status: {
    id: "invitation_status",
    defaultMessage: "Invitation Status",
  },
  age: { id: "age", defaultMessage: "Age" },
  daily_login_activity: {
    id: "daily_login_activity",
    defaultMessage: "Daily Login Activity",
  },
  active_today: { id: "active_today", defaultMessage: "Active Today" },
  ios_android_pct: {
    id: "ios_android_pct",
    defaultMessage: "iOS/Android (Pct.)",
  },
  new_high: { id: "new_high", defaultMessage: "  New High!" },
  no_of_staff: { id: "no_of_staff", defaultMessage: "No of Staff" },
  no_of_patients: { id: "no_of_patients", defaultMessage: "No of Patients" },

  month: { id: "month", defaultMessage: "Month" },
  week: { id: "week", defaultMessage: "Week" },
  work_week: { id: "work_week", defaultMessage: "Work Week" },
  day: { id: "day", defaultMessage: "Day" },
  agenda: { id: "agenda", defaultMessage: "Agenda" },
  patient_not_active_yet: {
    id: "patient_not_active_yet",
    defaultMessage: "Patient is not active yet.",
  },
  private_q: { id: "private_q", defaultMessage: "Private?" },
  select_template: {
    id: "select_template",
    defaultMessage: "Select from template",
  },
  copy_to_staff_notes: {
    id: "copy_to_staff_notes",
    defaultMessage: "Copy to Staff Notes",
  },
  task_focus: { id: "task_focus", defaultMessage: "Task Focus" },
  show_private_tasks: {
    id: "show_private_tasks",
    defaultMessage: "Show Only Private Tasks?",
  },
  by_sender: { id: "by_sender", defaultMessage: "by {senderName}" },
  select_pre_canned: {
    id: "select_pre_canned",
    defaultMessage: "Select a pre-canned message",
  },
  add_message_to_interaction: {
    id: "add_message_to_interaction",
    defaultMessage: "Add Message Content to Patient Interaction?",
  },
  tomorrow: { id: "tomorrow", defaultMessage: "Tomorrow" },
  days_7: { id: "days_7", defaultMessage: "Last 7 Days" },
  next7days: { id: "next7days", defaultMessage: "Next 7 Days" },
  days_10: { id: "days_10", defaultMessage: "Last 10 Days" },
  next10days: { id: "next10days", defaultMessage: "Next 10 Days" },
  month_1: { id: "month_1", defaultMessage: "Last 30 Days" },
  next1month: { id: "next1month", defaultMessage: "Next 30 Days" },
  date_range: { id: "date_range", defaultMessage: "Date Range" },
  created_on: { id: "created_on", defaultMessage: "Created Date" },
  created_on_newest_first: {
    id: "created_on_newest_first",
    defaultMessage: "Created Date (Newest first)",
  },
  created_on_oldest_first: {
    id: "created_on_oldest_first",
    defaultMessage: "Created Date (Oldest first)",
  },
  modified_on_newest_first: {
    id: "modified_on_newest_first",
    defaultMessage: "Modified Date (Newest first)",
  },
  modified_on_oldest_first: {
    id: "modified_on_oldest_first",
    defaultMessage: "Modified Date (Oldest first)",
  },
  name_a_z: { id: "name_a_z", defaultMessage: "Name (A to Z)" },
  name_z_a: { id: "name_z_a", defaultMessage: "Name (Z to A)" },
  title_a_z: { id: "title_a_z", defaultMessage: "Title (A to Z)" },
  title_z_a: { id: "title_z_a", defaultMessage: "Title (Z to A)" },

  accepted_date: { id: "accepted_date", defaultMessage: "Accepted Date" },
  completed_date: { id: "completed_date", defaultMessage: "Completed Date" },
  date_range_em: {
    id: "date_range_em",
    defaultMessage: "Date Range should be less than or equal to thirty days.",
  },
  no_data_available: {
    id: "no_data_available",
    defaultMessage: "No data available",
  },
  done: { id: "done", defaultMessage: "Done" },
  n_days_on_treatment: {
    id: "n_days_on_treatment",
    defaultMessage: "Days on treatment: {dayNumberInTreatment}",
  },
  n_days_to_treatment: {
    id: "n_days_to_treatment",
    defaultMessage: "{dayNumberInTreatment} days to Treatment",
  },
  n_days_to_procedure: {
    id: "n_days_to_procedure",
    defaultMessage: "{dayNumberInTreatment} days to Procedure",
  },

  send_update_to: {
    id: "send_update_to",
    defaultMessage: "You are about to send status update to ",
  },
  add_notes_yourself: {
    id: "add_notes_yourself",
    defaultMessage: "You are about to add notes for yourself for ",
  },
  add_staff_notes: {
    id: "add_staff_notes",
    defaultMessage: "You are about to add your notes under Staff Notes for ",
  },
  status_messages: { id: "status_messages", defaultMessage: "Status Messages" },
  hide_messages_activation: {
    id: "hide_messages_activation",
    defaultMessage: "Hide Messages After Patient Activation",
  },
  patient_type_w_value: {
    id: "patient_type_w_value",
    defaultMessage: "Patient Type: {patientType}",
  },
  speciality: { id: "speciality", defaultMessage: "Speciality" },
  clinic_name: { id: "clinic_name", defaultMessage: "Clinic Name" },
  caller: { id: "caller", defaultMessage: "Caller" },
  date_from_to: { id: "date_from_to", defaultMessage: "Date (From and To)" },

  profile: { id: "profile", defaultMessage: "Profile" },
  change_space: { id: "change_space", defaultMessage: "Change Space" },
  logout: { id: "logout", defaultMessage: "Logout" },
  confirm_to_go_ahead: {
    id: "confirm_to_go_ahead",
    defaultMessage: "Please confirm to go ahead.",
  },
  v_name_space: {
    id: "v_name_space",
    defaultMessage: "{name}'s Space.",
  },
  message_visible_to: {
    id: "message_visible_to",
    defaultMessage: "This message will be visible to {label}",
  },
  clinic_report_schedule: {
    id: "clinic_report_schedule",
    defaultMessage: "Clinic Report - {scheduleTitle}",
  },
  clinics_upload_report: {
    id: "clinics_upload_report",
    defaultMessage: "Clinics Upload Report",
  },
  upload_appointments: {
    id: "upload_appointments",
    defaultMessage: "Upload Appointments",
  },
  upload: { id: "upload", defaultMessage: "Upload" },
  clinic_lc: { id: "clinic_lc", defaultMessage: "clinic" },
  clinics_lc: { id: "clinics_lc", defaultMessage: "clinics" },
  change_to_grid_view: {
    id: "change_to_grid_view",
    defaultMessage: "Change to Grid View",
  },
  change_to_list_view: {
    id: "change_to_list_view",
    defaultMessage: "Change to List View",
  },
  import_results: { id: "import_results", defaultMessage: "Import Results" },
  v_all_clinic_reports: {
    id: "v_all_clinic_reports",
    defaultMessage: "View All Clinic Reports",
  },
  clinic_upload_results: {
    id: "clinic_upload_results",
    defaultMessage: "Clinic Upload Results",
  },
  no_clinics_found: {
    id: "no_clinics_found",
    defaultMessage:
      "No clinics found. Try a different date range in the filter.",
  },

  password_reset_failed: {
    id: "password_reset_failed",
    defaultMessage: "Request for password reset has failed. Please try again.",
  },

  check_your_sms_email: {
    id: "check_your_sms_email",
    defaultMessage:
      "Please check your SMS and/or Email. We have sent you the steps to reset your password.",
  },

  verify_contact_details: {
    id: "verify_contact_details",
    defaultMessage: "Verify Your Contact Details",
  },
  modify_your_contact_details: {
    id: "modify_your_contact_details",
    defaultMessage:
      "Do you need to update your contact details? Here’s what we currently have on our system. One of either email or phone is required.",
  },
  set_phone_email: {
    id: "set_phone_email",
    defaultMessage: "Please set a valid phone number or email.",
  },
  set_valid_phone: {
    id: "set_valid_phone",
    defaultMessage: "Please set a valid phone number with country code.",
  },
  confirm_phone: { id: "confirm_phone", defaultMessage: "Confirm Phone" },
  same_confirm_phone: {
    id: "same_confirm_phone",
    defaultMessage: "Phone and Confirm phone should be the same.",
  },
  e_mail: { id: "e_mail", defaultMessage: "E-mail" },
  phone_cc: { id: "phone_cc", defaultMessage: "Phone" },
  set_valid_email: {
    id: "set_valid_email",
    defaultMessage: "Please set a valid email.",
  },
  confirm_e_mail: { id: "confirm_e_mail", defaultMessage: "Confirm E-mail" },
  same_confirm_email: {
    id: "same_confirm_email",
    defaultMessage: "Email and Confirm Email should be the same.",
  },
  verify_update: { id: "verify_update", defaultMessage: "Verify and Update" },

  embedded_videos: {
    id: "embedded_videos",
    defaultMessage: "Sorry, your browser doesn't support embedded videos.",
  },
  lets_get_started: {
    id: "lets_get_started",
    defaultMessage: "Let's Get Started",
  },

  e_p_confirmed: {
    id: "e_p_confirmed",
    defaultMessage: "Congratulations! Your {linkType} has been confirmed.",
  },
  download_app: {
    id: "download_app",
    defaultMessage:
      "Please download the application on your mobile device. After installing the app, Sign In using your username and password.",
  },
  sign_in_uc: { id: "sign_in_uc", defaultMessage: "SIGN IN" },

  already_accepted_invitation: {
    id: "already_accepted_invitation",
    defaultMessage:
      "You have already accepted the invitation and your account is active.",
  },
  link_reset_password: {
    id: "link_reset_password",
    defaultMessage:
      "Have you forgotten your username and password? No problem, we will send you a link to reset password, to your",
  },
  phone: { id: "phone", defaultMessage: "phone" },
  email_address: { id: "email_address", defaultMessage: "email address" },
  phone_number: { id: "phone_number", defaultMessage: "phone number" },
  already_activated: {
    id: "already_activated",
    defaultMessage:
      "You are seeing this page because you attempted to Activate your Account from an email or SMS. It appears you may have done this already at least once. If so, your account is active. Now, you just need to download, install the myPatientSpace App, and login using the credentials you setup.",
  },
  can_reset_pwd: {
    id: "can_reset_pwd",
    defaultMessage:
      "If you don't remember your username or password, you can reset your password",
  },
  send_you_username: {
    id: "send_you_username",
    defaultMessage:
      ", by entering the email or phone number you used when signing up (we will send you your username).",
  },

  reach_us_on: {
    id: "reach_us_on",
    defaultMessage: "Please reach out to us on",
  },
  or: { id: "or", defaultMessage: "or" },
  problems_signing_up: {
    id: "problems_signing_up",
    defaultMessage: "if you continue to have problems signing up.",
  },
  contact_for_invitation: {
    id: "contact_for_invitation",
    defaultMessage:
      "if you continue to have problems signing up, or contact your provider to request a new invitation.",
  },

  dont_rem_pwd: {
    id: "dont_rem_pwd",
    defaultMessage: "If you don't remember the username or password, click",
  },
  to_reset: { id: "to_reset", defaultMessage: "to reset." },
  here: { id: "here", defaultMessage: "here" },

  wc_download_app: {
    id: "wc_download_app",
    defaultMessage:
      "Welcome back! You have just joined {facilityName}. You already have an account with myPatientSpace. Please download and login on the app.",
  },

  set_your_credentials: {
    id: "set_your_credentials",
    defaultMessage: "Set Your Credentials",
  },
  set_username_password: {
    id: "set_username_password",
    defaultMessage: "Set username and password for your account.",
  },
  user_name: { id: "user_name", defaultMessage: "Username" },
  user_name_ht: {
    id: "user_name_ht",
    defaultMessage:
      "Either valid email or only letters, numbers, underline, and one period are allowed",
  },
  email_as_username: {
    id: "email_as_username",
    defaultMessage: "Use my email as username",
  },
  set_username: { id: "set_username", defaultMessage: "Please set username." },
  password: { id: "password", defaultMessage: "Password" },
  password_ht: {
    id: "password_ht",
    defaultMessage:
      "Password should contain at least one lower case letter, one upper case letter, one digit, and a minimum of 8 characters.",
  },
  set_valid_password: {
    id: "set_valid_password",
    defaultMessage: "Please set valid password.",
  },
  confirm_password: {
    id: "confirm_password",
    defaultMessage: "Confirm Password",
  },
  passwords_mustbe_same: {
    id: "passwords_mustbe_same",
    defaultMessage: "Confirm password must be same as password.",
  },
  create_account: { id: "create_account", defaultMessage: "Create Account" },
  accept_tc: { id: "accept_tc", defaultMessage: "Accept Terms and Conditions" },

  confirm_identity: {
    id: "confirm_identity",
    defaultMessage: "Confirm Identity",
  },
  to_accept_your_invitation: {
    id: "to_accept_your_invitation",
    defaultMessage:
      "To accept your invitation, please confirm your identity by providing the following information.",
  },
  accept_pp_tc: {
    id: "accept_pp_tc",
    defaultMessage: "Please accept privacy policy and terms and conditions.",
  },
  to_sign_up_service: {
    id: "to_sign_up_service",
    defaultMessage:
      "To Sign Up to {appName} service, please enter the following details to confirm your identity.",
  },
  note: {
    id: "note",
    defaultMessage: "Note:",
  },
  contact_if_no_details: {
    id: "contact_if_no_details",
    defaultMessage:
      "If you don't have these details, please contact {facilityName} to get them.",
  },

  accept_invite: {
    id: "accept_invite",
    defaultMessage: "Accept Invite",
  },

  contact_support_at: {
    id: "contact_support_at",
    defaultMessage:
      "If you're having trouble with setting up your account, please contact support at:",
  },

  account_setup: {
    id: "account_setup",
    defaultMessage: "Account Setup",
  },

  account_setup_for: {
    id: "account_setup_for",
    defaultMessage: "Account Setup for {facilityName}",
  },

  please_contact: {
    id: "please_contact",
    defaultMessage:
      "If you believe you've entered the code correctly but are seeing an error, please contact",
  },

  region: {
    id: "region",
    defaultMessage: "Region",
  },

  myPatientSpace_support: {
    id: "myPatientSpace_support",
    defaultMessage: "myPatientSpace Support",
  },

  invite_you_received: {
    id: "invite_you_received",
    defaultMessage:
      "If you've been sent an invite that you received via Email or SMS text, please click the link there and follow on-screen instructions to setup your account.",
  },

  contact_the_organization: {
    id: "contact_the_organization",
    defaultMessage:
      "Contact the Organization or Healthcare Provider who invited you if you do not have this information. The code would have been provided to you at the hospital, or on a printed leaflet.",
  },

  continue: {
    id: "continue",
    defaultMessage: "Continue",
  },

  hospital_code: {
    id: "hospital_code",
    defaultMessage: "Hospital Code",
  },

  invalid_code: {
    id: "invalid_code",
    defaultMessage: "Invalid Code. Please try again.",
  },

  start_entering_code: {
    id: "start__entering_code",
    defaultMessage:
      "To use you need to create an account. Start by entering the Code given by your Health Care Provider.",
  },

  invite_only: {
    id: "invite_only",
    defaultMessage: "This Service is Invite-Only",
  },

  invalid_link: {
    id: "invalid_link",
    defaultMessage: "Invalid link or facility code",
  },

  login_to_account: {
    id: "login_to_account",
    defaultMessage: "Login to your Account",
  },

  error_logging_in: {
    id: "error_logging_in",
    defaultMessage:
      "Error logging in. Please provide correct Username and Password.",
  },

  forgot_password: {
    id: "forgot_password",
    defaultMessage: "Forgot password?",
  },

  welcome_back: {
    id: "welcome_back",
    defaultMessage:
      "Welcome back! You have just joined {facilityName}. You already have an account with myPatientSpace. Please login to access your account.",
  },

  caps_lock_on: {
    id: "caps_lock_on",
    defaultMessage: "Caps Lock On",
  },

  login: {
    id: "login",
    defaultMessage: "Login",
  },

  view_search_filters: {
    id: "view_search_filters",
    defaultMessage: "View Search Filters",
  },
  hide_search_filters: {
    id: "hide_search_filters",
    defaultMessage: "Hide Search Filters",
  },
  save_search: { id: "save_search", defaultMessage: "Save Search" },
  delete_saved_search: {
    id: "delete_saved_search",
    defaultMessage: "Delete Saved Search",
  },
  onboarded: { id: "onboarded", defaultMessage: "Onboarded" },
  my_watchlist: { id: "my_watchlist", defaultMessage: "My Watchlist" },
  pending_invitation: {
    id: "pending_invitation",
    defaultMessage: "Pending Invitation",
  },
  active_internal: { id: "active_internal", defaultMessage: "Active Internal" },
  rip_inactive: { id: "rip_inactive", defaultMessage: "RIP Inactive" },
  start_now: { id: "start_now", defaultMessage: "Start Now" },
  schedule_call: { id: "schedule_call", defaultMessage: "Schedule Call" },
  new_message: {
    id: "new_message",
    defaultMessage: "New Message",
  },
  see_more: { id: "see_more", defaultMessage: "See more" },
  booking: {
    id: "booking",
    defaultMessage: "Booking",
  },

  appt_no_of_slots_per_appt: {
    id: "appt_no_of_slots_per_appt",
    defaultMessage: "No Of Slots Per Appointment",
  },
  short_no_of_slots_per_appt: {
    id: "short_no_of_slots_per_appt",
    defaultMessage: "Slots/Appt",
  },
  appt_messages_configured: {
    id: "appt_messages_configured",
    defaultMessage: "Messages",
  },
  appt_color_code: {
    id: "appt_color_code",
    defaultMessage: "Color Code",
  },
  export_data: {
    id: "export_data",
    defaultMessage: "Export Data",
  },
  content_library: {
    id: "content_library",
    defaultMessage: "Content Library",
  },

  team_calendars: {
    id: "team_calendars",
    defaultMessage: "Team Calendars",
  },

  my_calendar: {
    id: "my_calendar",
    defaultMessage: "Calendar",
  },

  new_booking: {
    id: "new_booking",
    defaultMessage: "New Booking",
  },

  manage_schedules: {
    id: "manage_schedules",
    defaultMessage: "Manage Schedules",
  },

  my_clinics: {
    id: "my_clinics",
    defaultMessage: "My Clinics",
  },

  fa_template_copied: {
    id: "fa_template_copied",
    defaultMessage: "Appointment Template has been copied.",
  },
  instructions: {
    id: "instructions",
    defaultMessage: "Instructions",
  },

  clinic_report_sent: {
    id: "clinic_report_sent",
    defaultMessage: "Report has been sent to the recipients.",
  },

  import_library_item: {
    id: "import_library_item",
    defaultMessage: "Import Library Item",
  },

  add_dosage: {
    id: "add_dosage",
    defaultMessage: "Add Dosage",
  },

  edit_dosage: {
    id: "edit_dosage",
    defaultMessage: "Edit Dosage",
  },

  delete_dosage: {
    id: "delete_dosage",
    defaultMessage: "Delete Dosage",
  },

  to_delete_dosage: {
    id: "to_delete_dosage",
    defaultMessage: "Are you sure you want to delete the Dosage?",
  },

  to_delete_medication: {
    id: "to_delete_medication",
    defaultMessage:
      "Are you sure you want to delete the medication? It will be permanently deleted from the system and can not be reverted.",
  },

  medication_added: {
    id: "medication_added",
    defaultMessage: "Medication has been added",
  },

  medication_deleted: {
    id: "medication_deleted",
    defaultMessage: "Medication has been deleted",
  },

  medication_updated: {
    id: "medication_updated",
    defaultMessage: "Medication has been updated",
  },

  medications_uc: {
    id: "medications_uc",
    defaultMessage: "MEDICATIONS",
  },

  add_medication: {
    id: "add_medication",
    defaultMessage: "Add Medication",
  },

  delete_medication: {
    id: "delete_medication",
    defaultMessage: "Delete Medication",
  },

  edit_medication: {
    id: "edit_medication",
    defaultMessage: "Edit Medication",
  },

  medications: {
    id: "medications",
    defaultMessage: "Medications",
  },

  medication: {
    id: "medication",
    defaultMessage: "Medication",
  },

  form_medication: {
    id: "form_medication",
    defaultMessage: "Form of Medication",
  },
  survey_topics: {
    id: "survey_topics",
    defaultMessage: "Survey Topics",
  },

  task_topics: {
    id: "task_topics",
    defaultMessage: "Task Topics",
  },

  content_type: {
    id: "content_type",
    defaultMessage: "Content Type",
  },
  appointment_reopened: {
    id: "appointment_reopened",
    defaultMessage: "Appointment has been reopened.",
  },

  reopen_appointment: {
    id: "reopen_appointment",
    defaultMessage: "Reopen Appointment",
  },

  clear_care_plan_history: {
    id: "clear_care_plan_history",
    defaultMessage: "Clear Care Plan History",
  },

  care_plan_history_cleared: {
    id: "care_plan_history_cleared",
    defaultMessage: "Care Plan history has been cleared.",
  },

  sure_to_clear_cp_history: {
    id: "sure_to_clear_cp_history",
    defaultMessage: "Are you sure you want to clear the care plan history?",
  },

  task_sent: {
    id: "task_sent",
    defaultMessage:
      "Thank you. Your request has been received and will be processed shortly.",
  },

  edit_profile: {
    id: "edit_profile",
    defaultMessage: "Edit Profile",
  },

  clinic: {
    id: "clinic",
    defaultMessage: "Clinic",
  },

  survey_imported: {
    id: "survey_imported",
    defaultMessage: "Survey has been imported.",
  },

  could_not_import_survey: {
    id: "could_not_import_survey",
    defaultMessage:
      "Could not import the survey. Please check the file for valid JSON and try again.",
  },

  rule_deleted: {
    id: "rule_deleted",
    defaultMessage: "Rule Map has been deleted.",
  },

  information: {
    id: "information",
    defaultMessage: "Information",
  },

  sort_order_in_use: {
    id: "sort_order_in_use",
    defaultMessage: "Sort order is already in use.",
  },

  fa_cancel_reminders_config: {
    id: "fa_cancel_reminders_config",
    defaultMessage: "Cancellation Messages Configuration",
  },

  fa_reschedule_reminders_config: {
    id: "fa_reschedule_reminders_config",
    defaultMessage: "Reschedule Messages Configuration",
  },

  phno_mandatory_4_patients: {
    id: "phno_mandatory_4_patients",
    defaultMessage: "Phone Number Mandatory for Patients?",
  },

  participants_updated: {
    id: "participants_updated",
    defaultMessage: "Participants have been updated.",
  },

  noAuditsAvailable: {
    id: "no_audits_available",
    defaultMessage: "No audits available.",
  },

  audit_logs: {
    id: "audit_logs",
    defaultMessage: "Audit Logs",
  },

  adhoc_new_patient: {
    id: "adhoc_new_patient",
    defaultMessage: "Add Ad-hoc Patient for Appointment",
  },

  no_patients_for_keyword: {
    id: "no_patients_for_keyword",
    defaultMessage: "No matching patients found for the given keyword.",
  },

  notify_all_in_channel: {
    id: "notify_all_in_channel",
    defaultMessage: "Notify All in the Channel?",
  },

  channel_updated: {
    id: "channel_updated",
    defaultMessage: "Channel has been Updated.",
  },

  optional: {
    id: "optional",
    defaultMessage: "(Optional)",
  },

  appt_notif_sent: {
    id: "appt_notif_sent",
    defaultMessage: "Link has been sent to all participants.",
  },

  new_schedule: {
    id: "new_schedule",
    defaultMessage: "New Schedule",
  },

  link_shared: {
    id: "link_shared",
    defaultMessage: "Link has been shared.",
  },

  rule_created: {
    id: "rule_created",
    defaultMessage: "Rule has been created",
  },
  rule_edited: {
    id: "rule_edited",
    defaultMessage: "Rule has been edited",
  },
  channel_add_error: {
    id: "channel_add_error",
    defaultMessage:
      "Error while creating channel {name}. Please try again later",
  },

  create_channel: {
    id: "create_channel",
    defaultMessage: "Create Channel",
  },

  channels: {
    id: "channels",
    defaultMessage: "Channels",
  },

  not_taken_assignments: {
    id: "not_taken_assignments",
    defaultMessage: "You have not taken any assignments yet",
  },

  pt_not_taken_assignments: {
    id: "pt_not_taken_assignments",
    defaultMessage: "This patient has not taken any assessments yet.",
  },

  // m: {
  //   id: "",
  //   defaultMessage: ""
  // },

  patient_interactions: {
    id: "patient_interactions",
    defaultMessage: "Patient Interactions",
  },

  select_patient: {
    id: "select_patient",
    defaultMessage: "Select Patient",
  },

  self: {
    id: "self",
    defaultMessage: "Self",
  },

  care_teams: {
    id: "care_teams",
    defaultMessage: "Care Teams",
  },

  copied_appt_sch: {
    id: "copied_appt_sch",
    defaultMessage:
      "Appointment Schedule URL has been copied to the clipboard.",
  },
  appt_schedule_w_name: {
    id: "appt_schedule_w_name",
    defaultMessage: "Appointment Schedule - {name}",
  },

  copied: {
    id: "copied",
    defaultMessage: "Copied!",
  },

  copied_cp: {
    id: "copied_cp",
    defaultMessage: "Copied to clipboard.",
  },

  copied_cp_safari: {
    id: "copied_cp_safari",
    defaultMessage: "Copied to clipboard, please paste into Safari browser.",
  },

  patients_imported: {
    id: "patients_imported",
    defaultMessage: "Patients have been imported.",
  },

  staff_imported: {
    id: "staff_imported",
    defaultMessage: "Staff have been imported.",
  },

  import_text: {
    id: "import_text",
    defaultMessage: "Import",
  },

  confirm_appointment: {
    id: "confirm_appointment",
    defaultMessage: "Confirm Appointment",
  },
  vc_appointment_confirmation: {
    id: "vc_appointment_confirmation",
    defaultMessage: "Scheduling Video Call with {patientName} for:",
  },
  vc_patient_error: {
    id: "vc_patient_error",
    defaultMessage:
      "Video call is available only for patients who has set up their myPatientSpace account.",
  },

  vc_appt_created: {
    id: "vc_appt_created",
    defaultMessage:
      "Created video call appointment with {patientName} for {dateTime}",
  },

  appt_created_w_name: {
    id: "appt_created_w_name",
    defaultMessage: "Created appointment with {patientName} for {dateTime}",
  },

  edit_contact: {
    id: "edit_contact",
    defaultMessage: "Edit Contact",
  },

  contact_updated: {
    id: "contact_updated",
    defaultMessage: " Contact has been updated",
  },

  contact_added: {
    id: "contact_added",
    defaultMessage: "Contact has been added",
  },

  contact_add_failed: {
    id: "contact_add_failed",
    defaultMessage: "Unable to add contact. Please try again later.",
  },

  contact_removed: {
    id: "contact_removed",
    defaultMessage: "Contact has been removed.",
  },

  remove_contact: {
    id: "remove_contact",
    defaultMessage: "Remove Contact",
  },

  add_contact: {
    id: "add_contact",
    defaultMessage: "Add Contact",
  },

  no_contacts: {
    id: "no_contacts",
    defaultMessage: "No Contacts",
  },

  placeholder_created: {
    id: "placeholder_created",
    defaultMessage: "Placeholder has been created",
  },
  create_placeholder_failed: {
    id: "create_placeholder_failed",
    defaultMessage: "Placeholder creation failed.",
  },

  video_call: {
    id: "video_call",
    defaultMessage: "Video Call",
  },

  tasks_stats_status: {
    id: "tasks_stats_status",
    defaultMessage: "Status - {status}",
  },

  tasks_stats_priority: {
    id: "tasks_stats_priority",
    defaultMessage: "Priority - {priority}",
  },

  tasks_stats_topic: {
    id: "tasks_stats_topic",
    defaultMessage: "Topic - {topic}",
  },

  tasks_stats_template: {
    id: "tasks_stats_template",
    defaultMessage: "Template - {template}",
  },

  tasks_for_name: {
    id: "tasks_for_name",
    defaultMessage: "{label} tasks for {user}",
  },

  add_status: {
    id: "add_status",
    defaultMessage: "Add Status",
  },

  special_notes_updated: {
    id: "special_notes_updated",
    defaultMessage: "Notes has been updated",
  },

  add_survey: {
    id: "add_survey",
    defaultMessage: "Add Survey",
  },

  profile_updated: {
    id: "profile_updated",
    defaultMessage: "Profile has been updated.",
  },

  pref_locale_updated: {
    id: "pref_locale_updated",
    defaultMessage:
      // "Preferred Locale has been updated. Please log out and log back in for the new locale to take effect."
      "Preferred Locale has been updated.",
  },

  content_preview: {
    id: "content_preview",
    defaultMessage: "Content Preview",
  },

  file_upload: {
    id: "file_upload",
    defaultMessage: "File Upload",
  },

  preview: {
    id: "preview",
    defaultMessage: "Preview",
  },

  html: {
    id: "html",
    defaultMessage: "HTML",
  },

  rich_text: {
    id: "rich_text",
    defaultMessage: "Rich Text",
  },

  type_content_here: {
    id: "type_content_here",
    defaultMessage: "Type content here.",
  },

  category: {
    id: "category",
    defaultMessage: "Category",
  },
  availability: {
    id: "availability",
    defaultMessage: "Availability",
  },
  public: {
    id: "public",
    defaultMessage: "Public",
  },
  private: {
    id: "private",
    defaultMessage: "Private",
  },
  all_members: {
    id: "all_members",
    defaultMessage: "All - All members in the Patient's space",
  },
  staff_ct_members: {
    id: "staff_ct_members",
    defaultMessage: "Staff",
  },
  patient_only: {
    id: "patient_only",
    defaultMessage: "Patient - Only for Patient",
  },
  patient_members: {
    id: "patient_members",
    defaultMessage: "Patient and Members",
  },
  status_all_roles: {
    id: "status_all_roles",
    defaultMessage: "All (Staff/Doctor, Patient and Family)",
  },
  status_all_roles_wo_family: {
    id: "status_all_roles_wo_family",
    defaultMessage: "All (Staff/Doctor and Patient)",
  },
  basic_text_update: {
    id: "basic_text_update",
    defaultMessage: "Basic Text update",
  },
  ideal_quick_notes: {
    id: "ideal_quick_notes",
    defaultMessage: "Ideal for quick notes",
  },
  pre_canned_messages: {
    id: "pre_canned_messages",
    defaultMessage: "Pre-canned messages",
  },
  welcome_texts_other: {
    id: "welcome_texts_other",
    defaultMessage: "Welcome texts, reminder & other",
  },
  images: {
    id: "images",
    defaultMessage: "Images",
  },
  notes_with_images: {
    id: "notes_with_images",
    defaultMessage: "Ideal for notes with images",
  },
  notes_with_audio_file: {
    id: "notes_with_audio_file",
    defaultMessage: "Ideal for notes with an audio file",
  },
  notes_with_video: {
    id: "notes_with_video",
    defaultMessage: "Ideal for notes with a video",
  },
  notes_with_pdf: {
    id: "notes_with_pdf",
    defaultMessage: "Ideal for documents",
  },
  library_surveys: {
    id: "library_surveys",
    defaultMessage: "Library of surveys",
  },
  content_library_items: {
    id: "content_library_items",
    defaultMessage: "Content Library Items",
  },
  consent_documents: {
    id: "consent_documents",
    defaultMessage: "Consent Documents",
  },
  task_templates: {
    id: "task_templates",
    defaultMessage: "Templates for creating Task",
  },
  audio: {
    id: "audio",
    defaultMessage: "Audio",
  },
  video: {
    id: "video",
    defaultMessage: "Video",
  },
  library_item: {
    id: "library_item",
    defaultMessage: "Library Item",
  },

  existing_files: {
    id: "existing_files",
    defaultMessage: "Existing Files",
  },

  set_files: {
    id: "set_files",
    defaultMessage: "Please set files",
  },

  set_status_text: {
    id: "set_status_text",
    defaultMessage: "Set status text and add templateParams using '_'",
  },

  set_template_text: {
    id: "set_template_text",
    defaultMessage: "Please set template text (minimum 4 chars)",
  },
  save_template: {
    id: "save_template",
    defaultMessage: "Save template",
  },

  attach_type_files: {
    id: "attach_type_files",
    defaultMessage: "Please attach {messageType} file(s).",
  },

  what_to_say: {
    id: "what_to_say",
    defaultMessage: "What would you like to say to {name}?",
  },

  template: {
    id: "template",
    defaultMessage: "Template",
  },

  new_template: {
    id: "new_template",
    defaultMessage: "New Template",
  },

  edit_template: {
    id: "edit_template",
    defaultMessage: "Edit Template",
  },
  tabs_reordered: {
    id: "tabs_reordered",
    defaultMessage: "Patient view tabs have been reordered and saved.",
  },
  survey_deleted: {
    id: "survey_deleted",
    defaultMessage: "Survey has been deleted.",
  },
  delete_survey: {
    id: "delete_survey",
    defaultMessage: "Delete Survey",
  },
  hard_delete_survey_msg: {
    id: "hard_delete_survey_msg",
    defaultMessage:
      "Are you sure you want to delete survey {survey}? It will be permanently deleted from the system and can not be reverted.",
  },
  delete_survey_msg: {
    id: "delete_survey_msg",
    defaultMessage:
      "Are you sure you want to delete survey {survey}? This can not be reverted.",
  },
  delete_template: {
    id: "delete_template",
    defaultMessage: "Delete Template - {template}",
  },
  delete_s_template_msg: {
    id: "delete_s_template_msg",
    defaultMessage:
      "Are you sure you want to delete template {title}? This can not be reverted.",
  },
  hard_delete_s_template_msg: {
    id: "hard_s_delete_template_msg",
    defaultMessage:
      "Are you sure you want to delete template {title}? It will be permanently deleted from the system and can not be reverted.",
  },
  s_template_created: {
    id: "s_template_created",
    defaultMessage: "Status Template has been created.",
  },
  s_template_create_error: {
    id: "s_template_create_error",
    defaultMessage: "Error while creating the template. {message}",
  },
  s_template_updated: {
    id: "s_template_updated",
    defaultMessage: "Status Template has been updated.",
  },
  s_template_update_err: {
    id: "s_template_update_err",
    defaultMessage:
      "Error while updating the template. Please try again later.",
  },
  attributes_saved: {
    id: "attributes_saved",
    defaultMessage:
      "Attribute changes have been saved. Please submit it to save in the backend.",
  },
  survey_submitted: {
    id: "survey_submitted",
    defaultMessage: "Survey answers have been submitted.",
  },
  s_template_ret_error: {
    id: "s_template_ret_error",
    defaultMessage:
      "Error while retrieving the template. Please try again later.",
  },
  care_plan_not_exist: {
    id: "care_plan_not_exist",
    defaultMessage: "Care Plan that you have chosen does not exist.",
  },
  care_plan_assigned: {
    id: "care_plan_assigned",
    defaultMessage: "Care Plan has been assigned.",
  },
  sure_to_complete: {
    id: "sure_to_complete",
    defaultMessage: "Are you sure you want to complete the care plan?",
  },
  error_assigning_cp: {
    id: "error_assigning_cp",
    defaultMessage:
      "Error while assigning the Care Plan. Please try again later.",
  },
  req_complete_cp: {
    id: "req_complete_cp",
    defaultMessage: "Request to complete Care Plan has been triggered.",
  },

  view_mode: {
    id: "view_mode",
    defaultMessage: "View Mode",
  },
  trigger_mode: {
    id: "trigger_mode",
    defaultMessage: "Trigger Mode",
  },

  cp_triggered: {
    id: "cp_triggered",
    defaultMessage: "Care Plan has been triggered for the mentioned date.",
  },

  both_reqd: {
    id: "both_reqd",
    defaultMessage: "Both the fields are required",
  },

  refresh: {
    id: "refresh",
    defaultMessage: "Refresh",
  },
  view_answers: {
    id: "view_answers",
    defaultMessage: "View Answers",
  },

  no_assigned_care_plans: {
    id: "no_assigned_care_plans",
    defaultMessage: "You have not been assigned any care plans yet.",
  },
  milestone_with_title: {
    id: "milestone_with_title",
    defaultMessage: "Milestone - {title}",
  },

  report_last_generated_at: {
    id: "report_last_generated_at",
    defaultMessage: "Report last generated at: {date}.",
  },

  day_of_treatment: {
    id: "day_of_treatment",
    defaultMessage: "Day {dayNumberInTreatment} of {treatmentDuration}",
  },

  assign_care_plan: {
    id: "assign_care_plan",
    defaultMessage: "Assign a Care Plan",
  },
  open_test_mode: {
    id: "open_test_mode",
    defaultMessage: "Open Test Mode",
  },
  complete_care_plan: {
    id: "complete_care_plan",
    defaultMessage: "Complete Care Plan",
  },

  download_as_csv: {
    id: "download_as_csv",
    defaultMessage: "Download as CSV",
  },

  change_care_plan: {
    id: "change_care_plan",
    defaultMessage: "Change Care Plan",
  },
  milestones: {
    id: "milestones",
    defaultMessage: "Milestones",
  },
  todays_activities: {
    id: "todays_activities",
    defaultMessage: "Today's Activities",
  },
  care_plan_test_mode: {
    id: "care_plan_test_mode",
    defaultMessage: "Care Plan Test Mode",
  },
  edit_assigned_care_plan: {
    id: "edit_assigned_care_plan",
    defaultMessage: "Edit Assigned Care Plan",
  },

  sh_survey_details: {
    id: "sh_survey_details",
    defaultMessage: "Survey Details",
  },
  new_uc: {
    id: "new_uc",
    defaultMessage: "NEW",
  },
  no_pending_tasks: {
    id: "no_pending_tasks",
    defaultMessage: "No pending tasks for today.",
  },
  sh_complete: {
    id: "sh_complete",
    defaultMessage: "Complete",
  },
  survey_pending: { id: "survey_pending", defaultMessage: "SURVEY: PENDING" },
  survey_completed: {
    id: "survey_completed",
    defaultMessage: "SURVEY: COMPLETED",
  },
  article: { id: "article", defaultMessage: "ARTICLE" },

  consent_pending: {
    id: "consent_pending",
    defaultMessage: "CONSENT: PENDING",
  },

  consent_completed: {
    id: "consent_completed",
    defaultMessage: "CONSENT: COMPLETED",
  },

  sh_appointment_type_md: {
    id: "sh_appointment_type_md",
    defaultMessage: "Appointment Type Master Data",
  },
  sh_appointment_location_md: {
    id: "sh_appointment_location_md",
    defaultMessage: "Appointment Schedule Location Master Data",
  },
  sh_appointment_vc_template: {
    id: "sh_appointment_vc_template",
    defaultMessage: "Appointment Template for Video calls",
  },
  fa_location: {
    id: "fa_location",
    defaultMessage: "Location",
  },
  sh_confirmation_messages: {
    id: "sh_confirmation_messages",
    defaultMessage: "Confirmation Messages",
  },
  sh_reminders_config: {
    id: "sh_reminders_config",
    defaultMessage: "Reminders Configuration",
  },
  sh_reminder_config_edit: {
    id: "sh_reminder_config_edit",
    defaultMessage: "Edit Reminder Configuration",
  },
  sh_delete: {
    id: "sh_delete",
    defaultMessage: "Delete",
  },
  sh_before_hours: {
    id: "sh_before_hours",
    defaultMessage: "Before Hours/Mins",
  },
  sh_email_sub: {
    id: "sh_email_sub",
    defaultMessage: "Email Subject",
  },
  sh_email_content: {
    id: "sh_email_content",
    defaultMessage: "Email Content",
  },
  sh_sms_content: {
    id: "sh_sms_content",
    defaultMessage: "SMS Content",
  },
  sh_status_message: {
    id: "sh_status_message",
    defaultMessage: "Status Message",
  },
  sh_submit: {
    id: "sh_submit",
    defaultMessage: "Submit",
  },
  sh_participants: {
    id: "sh_participants",
    defaultMessage: "Participants",
  },
  sh_type: {
    id: "sh_type",
    defaultMessage: "Type",
  },
  sh_master_data: {
    id: "sh_master_data",
    defaultMessage: "Master Data",
  },
  sh_master_data_key: {
    id: "sh_master_data_key",
    defaultMessage: "Master Data Key",
  },
  sh_master_data_value: {
    id: "sh_master_data_value",
    defaultMessage: "Master Data Value",
  },
  sh_staff: {
    id: "sh_staff",
    defaultMessage: "Staff",
  },
  sh_remove: {
    id: "sh_remove",
    defaultMessage: "Remove",
  },
  sh_add_participant: {
    id: "sh_add_participant",
    defaultMessage: "Add Participant",
  },
  sh_reminder_config: {
    id: "sh_reminder_config",
    defaultMessage: "Reminder Configuration",
  },
  sh_users: {
    id: "sh_users",
    defaultMessage: "Users",
  },
  sh_all_users: {
    id: "sh_all_users",
    defaultMessage: "All Users",
  },
  sh_all_patients: {
    id: "sh_all_patients",
    defaultMessage: "All Patients",
  },
  sh_selected_patients: {
    id: "sh_selected_patients",
    defaultMessage: "Selected Patients",
  },
  sh_search_patients: {
    id: "sh_search_patients",
    defaultMessage: "Patients From Search",
  },
  sh_set_campaign_mode: {
    id: "sh_set_campaign_mode",
    defaultMessage: "Please set campaign mode",
  },
  sh_set_sms_content: {
    id: "sh_set_sms_content",
    defaultMessage:
      "Please set sms content that does not exceed 160 characters.",
  },
  sh_set_subject: {
    id: "sh_set_subject",
    defaultMessage: "Please set subject",
  },
  sh_set_email_content: {
    id: "sh_set_email_content",
    defaultMessage: "Please set email content",
  },
  sh_send_messages_to: {
    id: "sh_send_messages_to",
    defaultMessage: "Send Messages To",
  },
  sh_50_patients: {
    id: "sh_50_patients",
    defaultMessage: "Maximum of 50 Patients",
  },
  sh_view: {
    id: "sh_view",
    defaultMessage: "View",
  },
  sh_patient_ids: {
    id: "sh_patient_ids",
    defaultMessage: "Patient Ids",
  },
  sh_set_patient_ids: {
    id: "sh_set_patient_ids",
    defaultMessage: "Please set patient Ids",
  },
  sh_set_number_of_patients: {
    id: "sh_set_number_of_patients",
    defaultMessage: "Please set Number of patients",
  },
  sh_set_one_of_message: {
    id: "sh_set_one_of_message",
    defaultMessage: "At least one the following messages is required",
  },
  sh_set_email_subject: {
    id: "sh_set_email_subject",
    defaultMessage: "Please set email subject",
  },
  sh_send_messages: {
    id: "sh_send_messages",
    defaultMessage: "Send Messages",
  },
  sh_sure_send_messages: {
    id: "sh_sure_send_messages",
    defaultMessage: "Are you sure you want to send the messages?",
  },
  patient: {
    id: "patient",
    defaultMessage: "Patient",
  },
  add: {
    id: "add",
    defaultMessage: "Add",
  },
  addNewPatient: {
    id: "add_new_patient",
    defaultMessage: "Add New Patient",
  },
  change: {
    id: "change",
    defaultMessage: "Change",
  },
  select: {
    id: "select",
    defaultMessage: "Select",
  },
  addPatient: {
    id: "add_patient",
    defaultMessage: "Add Patient",
  },
  patientType: {
    id: "patient_type",
    defaultMessage: "Patient Type",
  },
  setPatientType: {
    id: "set_patient_type",
    defaultMessage: "Please set patient type.",
  },
  consultant: {
    id: "consultant",
    defaultMessage: "Consultant",
  },
  set_consultant: {
    id: "set_consultant",
    defaultMessage: "Please set consultant.",
  },
  procedure: {
    id: "procedure",
    defaultMessage: "Procedure",
  },
  treatment: {
    id: "treatment",
    defaultMessage: "Treatment",
  },
  procedureDate: {
    id: "procedure_date",
    defaultMessage: "Procedure Date",
  },
  treatment_date: {
    id: "treatment_date",
    defaultMessage: "Treatment Date",
  },

  procedure_date_w_date: {
    id: "procedure_date_w_date",
    defaultMessage: "Procedure Date: {procedureDate}",
  },

  sure_to_deactivate_profile_w_name: {
    id: "sure_to_deactivate_profile_w_name",
    defaultMessage:
      "Are you sure you want to de-activate Patient profile {name}?",
  },

  sure_to_deactivate_profile: {
    id: "sure_to_deactivate_profile",
    defaultMessage: "Are you sure you want to de-activate Patient profile?",
  },

  submit: {
    id: "submit",
    defaultMessage: "Submit",
  },
  reset: {
    id: "reset",
    defaultMessage: "Reset",
  },
  filters: {
    id: "filters",
    defaultMessage: "Filters",
  },
  hideAdvanced: {
    id: "hide_advanced",
    defaultMessage: "Hide Advanced",
  },
  sortBy: {
    id: "sort_by",
    defaultMessage: "Sort By",
  },
  patients: {
    id: "patients",
    defaultMessage: "Patients",
  },
  advanced: {
    id: "advanced",
    defaultMessage: "Advanced",
  },
  agedDebt: {
    id: "aged_debt",
    defaultMessage: "Aged Debt",
  },
  watchlist: {
    id: "watchlist",
    defaultMessage: "Watchlist",
  },
  recent: {
    id: "recent",
    defaultMessage: "Recent",
  },
  removeFromWatchlist: {
    id: "remove_from_watchlist",
    defaultMessage: "Remove from watchlist",
  },
  addToWatchlist: {
    id: "add_to_watchlist",
    defaultMessage: "Add to watchlist",
  },
  create_new_task: {
    id: "create_new_task",
    defaultMessage: "Create Task",
  },
  submit_assessments: {
    id: "submit_assessments",
    defaultMessage: "Submit Assessments",
  },
  statuses_uc: {
    id: "statuses_uc",
    defaultMessage: "UPDATES",
  },
  reset_credentials: {
    id: "reset_credentials",
    defaultMessage: "Reset Credentials",
  },

  send_sms: {
    id: "send_sms",
    defaultMessage: "Send SMS",
  },

  send_email: {
    id: "send_email",
    defaultMessage: "Send Email",
  },

  reorder_tabs: {
    id: "reorder_tabs",
    defaultMessage: "Reorder Tabs",
  },

  de_activate_patient: {
    id: "de_activate_patient",
    defaultMessage: "De-Activate Patient",
  },

  not_viewed_by_patient: {
    id: "not_viewed_by_patient",
    defaultMessage: "Not Viewed By Patient",
  },

  staff_notes_uc: {
    id: "staff_notes_uc",
    defaultMessage: "NOTES",
  },

  patient_interactions_uc: {
    id: "patient_interactions_uc",
    defaultMessage: "COMMS",
  },

  care_plan_uc: {
    id: "care_plan_uc",
    defaultMessage: "CARE PLAN",
  },
  assessments_uc: {
    id: "assessments_uc",
    defaultMessage: "ASSESSMENTS",
  },
  tasks_uc: {
    id: "tasks_uc",
    defaultMessage: "TASKS",
  },
  appointments_uc: {
    id: "appointments_uc",
    defaultMessage: "APPTS.",
  },
  usage_uc: {
    id: "usage_uc",
    defaultMessage: "USAGE",
  },
  audit_logs_uc: {
    id: "audit_logs_uc",
    defaultMessage: "AUDITS",
  },
  resendInvite: {
    id: "resend_invite",
    defaultMessage: "Resend Invite",
  },
  sendInvite: {
    id: "send_invite",
    defaultMessage: "Send Invite",
  },
  restore: {
    id: "restore",
    defaultMessage: "Restore",
  },
  noPatients: {
    id: "no_patients",
    defaultMessage: "No patients.",
  },
  compactView: {
    id: "compact_view",
    defaultMessage: "Compact View",
  },
  mobilePhoneNumber: {
    id: "mobile_phone_number",
    defaultMessage: "Mobile Phone Number",
  },
  noLogs: {
    id: "no_logs",
    defaultMessage: "No logs available",
  },
  changeLogs: {
    id: "change_logs",
    defaultMessage: "Change Logs",
  },
  accessLogs: {
    id: "access_logs",
    defaultMessage: "Access Logs",
  },
  title: {
    id: "title",
    defaultMessage: "Title",
  },
  setTitle: {
    id: "setTitle",
    defaultMessage: "Please set title",
  },
  details: {
    id: "details",
    defaultMessage: "Details",
  },
  setDetails: {
    id: "set_details",
    defaultMessage: "Please set details",
  },
  visibility: {
    id: "visibility",
    defaultMessage: "Visibility",
  },
  documentType: {
    id: "document_type",
    defaultMessage: "Document Type",
  },
  pdf: {
    id: "pdf",
    defaultMessage: "PDF",
  },
  image: {
    id: "image",
    defaultMessage: "Image",
  },
  documents: {
    id: "documents",
    defaultMessage: "Documents",
  },
  setDocuments: {
    id: "set_documents",
    defaultMessage: "Please set documents",
  },
  topics: {
    id: "topics",
    defaultMessage: "Topics",
  },
  addDocument: {
    id: "add_document",
    defaultMessage: "Add Document",
  },
  description: {
    id: "description",
    defaultMessage: "Description",
  },
  noDocsFound: {
    id: "no_docs_found",
    defaultMessage: "No documents have been found for this patient",
  },
  markAsRIP: {
    id: "mark_as_rip",
    defaultMessage: "Mark as RIP",
  },
  cancel: {
    id: "cancel",
    defaultMessage: "Cancel",
  },
  yes: {
    id: "yes",
    defaultMessage: "Yes",
  },
  delete: {
    id: "delete",
    defaultMessage: "Delete",
  },
  noRecAssessments: {
    id: "no_rec_assessments",
    defaultMessage: "No recent assessments",
  },
  assessments: {
    id: "assessments",
    defaultMessage: "Assessments",
  },
  view: {
    id: "view",
    defaultMessage: "View",
  },
  send: {
    id: "send",
    defaultMessage: "Send",
  },
  sms: {
    id: "sms",
    defaultMessage: "SMS",
  },
  email: {
    id: "email",
    defaultMessage: "Email",
  },
  credentials: {
    id: "credentials",
    defaultMessage: "Credentials",
  },
  model: {
    id: "model",
    defaultMessage: "Model",
  },
  photos: {
    id: "photos",
    label: "Photos",
  },
  noDevsFound: {
    id: "no_devs_found",
    defaultMessage: "No devices have been found for this patient",
  },
  viewCompleteProfile: {
    id: "view_complete_profile",
    defaultMessage: "View Complete Profile",
  },
  save: {
    id: "save",
    defaultMessage: "Save",
  },
  dragDrop: {
    id: "drag_drop",
    defaultMessage: "Drag and drop to reorder the tabs",
  },
  yetToImpl: {
    id: "yet_to_impl",
    defaultMessage: "Yet to be implemented",
  },
  loginUserId: {
    id: "login_user_id",
    defaultMessage: "Login User Id",
  },
  setLoginUserId: {
    id: "set_login_user_id",
    defaultMessage: "Please set User Id",
  },
  sureToResetCreds: {
    id: "sure_to_reset_creds",
    defaultMessage:
      "Are you sure you want to reset the credentials for the patient?",
  },
  resetCredsWarningMsg: {
    id: "reset_creds_warning_msg",
    defaultMessage:
      "Clicking on the submit button will reset the credentials for the patient",
  },
  todayTasks: {
    id: "today_tasks",
    defaultMessage: "Today's Tasks",
  },
  viewTasks: {
    id: "view_tasks",
    defaultMessage: "View Tasks",
  },
  activities: {
    id: "activities",
    defaultMessage: "Activities",
  },
  name: {
    id: "name",
    defaultMessage: "Name",
  },
  relation: {
    id: "relation",
    defaultMessage: "Relation",
  },
  address: {
    id: "address",
    defaultMessage: "Address",
  },
  showAll: {
    id: "show_all",
    defaultMessage: "Show All",
  },
  editTaskData: {
    id: "edit_task_data",
    defaultMessage: "Edit Task Data",
  },
  editPatientProfile: {
    id: "edit_patient_profile",
    defaultMessage: "Edit Patient Profile",
  },
  rip: {
    id: "rip",
    defaultMessage: "RIP",
  },
  birthDate: {
    id: "birth_date",
    defaultMessage: "Birth Date",
  },
  notAvailable: {
    id: "not_available",
    defaultMessage: "Not Available",
  },
  lastActiveOn: {
    id: "last_active_on",
    defaultMessage: "Last Active On",
  },
  loginUserName: {
    id: "login_user_name",
    defaultMessage: "Login Username",
  },
  patientId: {
    id: "patient_id",
    defaultMessage: "Patient ID",
  },
  dob: {
    id: "dob",
    defaultMessage: "DOB",
  },
  fromDate: { id: "from_date", defaultMessage: "From Date" },
  toDate: { id: "to_date", defaultMessage: "To Date" },
  serialNumber: { id: "serial_number", defaultMessage: "Serial Number" },
  mode: { id: "mode", defaultMessage: "Mode" },
  statistics: { id: "statistics", defaultMessage: "Statistics" },
  usage: { id: "usage", defaultMessage: "Usage" },
  maskOnDates: { id: "mask_on_dates", defaultMessage: "Mask On Dates" },
  maskOffDates: { id: "mask_off_dates", defaultMessage: "Mask Off Dates" },
  leak: { id: "leak", defaultMessage: "Leak" },
  tgtIPAP: { id: "tgtIPAP", defaultMessage: "tgtIPAP" },
  spo: { id: "spo", defaultMessage: "SPO" },
  events: { id: "events", defaultMessage: "Events" },
  detailedReport: {
    id: "detailed_report",
    defaultMessage: "Detailed Report",
  },
  fetchDetailedReport: {
    id: "fetch_detailed_report",
    defaultMessage: "Fetch Detailed Report",
  },
  therapyComplianceReport: {
    id: "therapy_compliance_report",
    defaultMessage: "Therapy Compliance Report",
  },
  fetchTherapyComplianceReport: {
    id: "fetch_therapy_compliance_report",
    defaultMessage: "Fetch Therapy Compliance Report",
  },
  showConsolidatedReport: {
    id: "show_consolidated_report",
    defaultMessage: "Show Consolidated Report",
  },
  surveyDateAndScores: {
    id: "survey_date_and_scores",
    defaultMessage: "Assessment Date and Scores",
  },
  recentScore: { id: "recent_score", defaultMessage: "Recent Score" },
  answer: { id: "Ans", defaultMessage: "Answer" },
  na: { id: "na", defaultMessage: "NA" },
  totalScore: { id: "total_score", defaultMessage: "Total Score" },
  daywiseChartFor: {
    id: "day_wise_chart_for",
    defaultMessage: "Day wise Chart for",
  },
  answers: { id: "answers", defaultMessage: "Answers" },
  submissionDate: {
    id: "submission_date",
    defaultMessage: "Submission Date",
  },
  dateSurveyTaken: {
    id: "date_survey_taken",
    defaultMessage: "Date when the assessment was taken:",
  },
  surveyScore: { id: "survey_score", defaultMessage: "Assessment Score" },
  survey_value: { id: "survey_value", defaultMessage: "Assessment Value" },
  score: { id: "score", defaultMessage: "Score" },
  value: { id: "value", defaultMessage: "Value" },
  avgScoresQuestionsPeriod: {
    id: "avg_scores_qs_period",
    defaultMessage: "Average Scores Across Questions and Response Period",
  },
  timeWindow: { id: "time_window", defaultMessage: "Time Window" },
  completed: { id: "completed", defaultMessage: "Completed" },
  inComplete: { id: "in_complete", defaultMessage: "InComplete" },
  overallResponseRate: {
    id: "overall_response_rate",
    defaultMessage: "Overall response rate",
  },
  availableOptions: {
    id: "available_options",
    defaultMessage: "Available Options",
  },
  selectSurveyToView: {
    id: "select_survey_to_view",
    defaultMessage: "Please select a Survey to view the data",
  },
  survey: { id: "survey", defaultMessage: "Survey" },
  surveyQuestions: {
    id: "survey_questions",
    defaultMessage: "Survey Questions",
  },
  tasksByAssignee: {
    id: "tasks_by_assignee",
    defaultMessage: "Tasks By Assignee",
  },
  tasksByPriority: {
    id: "tasks_by_priority",
    defaultMessage: "Tasks By Priority",
  },
  tasksByStatus: {
    id: "tasks_by_status",
    defaultMessage: "Tasks By Status",
  },
  tasksByTopics: {
    id: "tasks_by_topics",
    defaultMessage: "Tasks By Topics",
  },
  tasksByTemplates: {
    id: "tasks_by_templates",
    defaultMessage: "Tasks By Templates",
  },
  date: { id: "date", defaultMessage: "Date" },
  taskTemplate: { id: "task_template", defaultMessage: "Task Template" },
  taskAssignee: { id: "task_assignee", defaultMessage: "Task Assignee" },
  taskCreatedBy: {
    id: "task_created_by",
    defaultMessage: "Task Created By",
  },
  tasks_count: { id: "tasks_count", defaultMessage: "{count} Tasks" },
  tasks: { id: "tasks", defaultMessage: "Tasks" },
  my_tasks: { id: "my_tasks", defaultMessage: "My Tasks" },
  team_tasks: { id: "team_tasks", defaultMessage: "Team Tasks" },
  tasks_by_me: {
    id: "tasks_by_me",
    defaultMessage: "Tasks Created By Me",
  },
  tasks_completed_by_me: {
    id: "tasks_completed_by_me",
    defaultMessage: "Tasks Completed By Me",
  },
  all_completed_tasks: {
    id: "all_completed_tasks",
    defaultMessage: "All Completed Tasks",
  },
  appointments: {
    id: "appointments",
    defaultMessage: "Appointments",
  },
  template_messages: {
    id: "template_messages",
    defaultMessage: "Template Messages",
  },
  dashboard: {
    id: "dashboard",
    defaultMessage: "Dashboard",
  },
  filter: { id: "filter", defaultMessage: "Filter" },
  close: { id: "close", defaultMessage: "Close" },
  surveys: { id: "surveys", defaultMessage: "Surveys" },
  staff: { id: "staff", defaultMessage: "Staff" },
  patientReport: {
    id: "patient_report",
    defaultMessage: "Patient Report",
  },
  setRelation: {
    id: "please_set_relation",
    defaultMessage: "Please set relation",
  },
  remove: { id: "remove", defaultMessage: "Remove" },
  noMembersYet: { id: "no_members_yet", defaultMessage: "No members yet" },
  addMember: { id: "add_member", defaultMessage: "Add Member" },
  removeMember: { id: "remove_member", defaultMessage: "Remove Member" },
  confirm: { id: "confirm", defaultMessage: "Confirm" },
  myNotes: { id: "my_notes", defaultMessage: "Notes to Myself" },
  addNotes: { id: "add_notes", defaultMessage: "Add Notes" },
  sendNewStatus: {
    id: "send_new_status",
    defaultMessage: "Send New Status",
  },
  noNotesYet: {
    id: "no_notes_yet",
    defaultMessage: "You don't have any notes yet.",
  },
  noStaffNotes: {
    id: "no_staff_notes",
    defaultMessage: "No staff notes yet for this patient.",
  },
  no_patient_interactions: {
    id: "no_patient_interactions",
    defaultMessage: "There are no patient interactions.",
  },
  deleteStaffNote: {
    id: "delete_staff_note",
    defaultMessage: "Delete Staff Note",
  },
  deleteStatusMessage: {
    id: "delete_status_message",
    defaultMessage: "Delete Status Message",
  },
  noStatuses: {
    id: "no_statuses",
    defaultMessage: "No statuses yet for this patient.",
  },
  no_statuses_channel: {
    id: "no_statuses_channel",
    defaultMessage: "No statuses yet for this channel.",
  },
  today: { id: "today", defaultMessage: "Today" },
  yesterday: { id: "yesterday", defaultMessage: "Yesterday" },
  edit: { id: "edit", defaultMessage: "Edit" },
  carePlanDetails: {
    id: "care_plan_details",
    defaultMessage: "Care Plan Details",
  },
  consentDocumentDetails: {
    id: "consent_document_details",
    defaultMessage: "Consent Document Details",
  },
  status: { id: "status", defaultMessage: "Status" },
  device: { id: "device", defaultMessage: "DEVICE" },
  deviceDetails: {
    id: "device_details",
    defaultMessage: "Device Details",
  },
  position: { id: "position", defaultMessage: "POSITION (Lat/Lng)" },
  surveyAnswers: {
    id: "survey_answers",
    defaultMessage: "Survey Answers",
  },
  taskCreatedAvailable: {
    id: "task_created_available",
    defaultMessage:
      "A Task has been created and its available in the tasks page.",
  },
  typeOfEntry: { id: "type_of_entry", defaultMessage: "Type Of Entry" },
  chooseUpdateType: {
    id: "choose_update_type",
    defaultMessage: "Please choose update type",
  },
  videoThumbnails: {
    id: "video_thumbnails",
    defaultMessage: "Video Thumbnails",
  },
  setThumbnails: {
    id: "set_thumbnails",
    defaultMessage: "Please Set Thumbnails",
  },
  setThumbnailsE: {
    id: "set_thumbnails_e",
    defaultMessage: "Please set thumbnails for all video files",
  },
  availableTemplates: {
    id: "available_templates",
    defaultMessage: "Available Templates",
  },
  chooseTemplate: {
    id: "choose_template",
    defaultMessage: "Please Choose Template",
  },
  chooseSurvey: { id: "choose_survey", defaultMessage: "Choose a survey" },
  pleaseChooseSurvey: {
    id: "please_choose_survey",
    defaultMessage: "Please Choose Survey",
  },
  library: { id: "library", defaultMessage: "Library" },
  pleaseSetLibrary: {
    id: "please_set_liLbrary",
    defaultMessage: "Please Set Library",
  },
  consentDocument: {
    id: "consent_document",
    defaultMessage: "Consent Document",
  },
  setConsentDocument: {
    id: "set_consent_document",
    defaultMessage: "Please Set Consent Document",
  },
  setTaskTemplate: {
    id: "set_task_template",
    defaultMessage: "Please Set Task Template",
  },
  text: { id: "text", defaultMessage: "Text" },
  setTextContent: {
    id: "please_set_text_content",
    defaultMessage: "Please Set Text Content",
  },
  optionalTitle: {
    id: "optional_title",
    defaultMessage: "Title (Optional)",
  },
  media: { id: "media", defaultMessage: "Media" },
  artifactType: { id: "artifact_type", defaultMessage: "Artifact Type" },
  sendUpdate: { id: "send_update", defaultMessage: "Send Update" },
  selectCD: { id: "select_cd", defaultMessage: "Select Consent Document" },
  selectTaskTemplate: {
    id: "select_task_template",
    defaultMessage: "Select Task Template",
  },
  newTask: { id: "new_task", defaultMessage: "New Task" },
  task: { id: "task", defaultMessage: "Task" },
  back: { id: "back", defaultMessage: "Back" },
  create: { id: "create", defaultMessage: "Create" },
  choosePatient: {
    id: "choose_patient",
    defaultMessage: "Choose Patient",
  },
  relatedTo: { id: "related_to", defaultMessage: "Related to" },
  setPatient: { id: "set_patient", defaultMessage: "Please set Patient" },
  chooseTaskType: {
    id: "choose_task_type",
    defaultMessage: "Choose Task Type",
  },
  adHocTask: { id: "ad_hoc_task", defaultMessage: "Ad Hoc Task" },
  next: { id: "next", defaultMessage: "Next" },
  assignee: { id: "assignee", defaultMessage: "Assignee" },
  pending: { id: "pending", defaultMessage: "Pending" },
  dueOn: { id: "due_on", defaultMessage: "Due On" },
  createdBy: { id: "created_by", defaultMessage: "Created By" },
  modifiedBy: { id: "edited_by", defaultMessage: "Edited By" },
  reassign: { id: "reassign", defaultMessage: "Reassign" },
  taskFor: { id: "task_for", defaultMessage: "Task For" },
  accept: { id: "accept", defaultMessage: "Accept" },
  assignToMe: { id: "assign_to_me", defaultMessage: "Assign To Me" },
  acceptNComplete: {
    id: "accept_complete",
    defaultMessage: "Accept & Complete",
  },
  markComplete: { id: "mark_complete", defaultMessage: "Mark Complete" },
  taskCompleteReopen: {
    id: "task_complete_reopen",
    defaultMessage: "Task Complete. Reopen?",
  },
  requester: { id: "requester", defaultMessage: "Requester" },
  taskNotes: { id: "task_notes", defaultMessage: "Task Notes" },
  attachments: { id: "attachments", defaultMessage: "Attachments" },
  taskDetails: { id: "task_details", defaultMessage: "Task Details" },
  taskAuditDetails: {
    id: "task_audit_details",
    defaultMessage: "Task Audit Details",
  },
  setTaskTitle: {
    id: "please_set_task_title",
    defaultMessage: "Please Set Task Title",
  },
  setTaskDescription: {
    id: "please_set_task_description",
    defaultMessage: "Please Set Task Description",
  },
  existingAttachments: {
    id: "existing_attachments",
    defaultMessage: "Existing Attachments",
  },
  attachmentsImages: {
    id: "attachments_images",
    defaultMessage: "Attachments (Images)",
  },
  pleaseSetAssignee: {
    id: "please_set_assignee",
    defaultMessage: "Please Set Assignee",
  },
  dueDate: { id: "due_date", defaultMessage: "Due Date" },
  taskPriority: { id: "task_priority", defaultMessage: "Task Priority" },
  asap: { id: "asap", defaultMessage: "ASAP" },
  urgent: { id: "urgent", defaultMessage: "Urgent" },
  normal: { id: "normal", defaultMessage: "Normal" },
  low: { id: "low", defaultMessage: "Low" },
  stat: { id: "stat", defaultMessage: "Stat" },
  noTasksAvailable: {
    id: "no_tasks_available",
    defaultMessage: "No Tasks Available.",
  },
  noTasksFound: {
    id: "no_tasks_found",
    defaultMessage: "No tasks have been found for the given criteria",
  },
  priority: { id: "priority", defaultMessage: "Priority" },
  pLastName: { id: "p_last_name", defaultMessage: "Patient Last Name" },
  pFirstName: { id: "p_first_name", defaultMessage: "Patient First Name" },
  aLastName: { id: "alast_name", defaultMessage: "Assignee Last Name" },
  aFirstName: { id: "afirst_name", defaultMessage: "Assignee First Name" },
  aCareTeam: { id: "acare_team", defaultMessage: "Assignee Care Team" },
  taskTitle: { id: "task_title", defaultMessage: "Task Title" },
  asc: { id: "asc", defaultMessage: "Asc" },
  desc: { id: "desc", defaultMessage: "Desc" },
  allDue: { id: "all_due", defaultMessage: "All Due" },
  dueToday: { id: "due_today", defaultMessage: "Due Today" },
  overdue: { id: "overdue", defaultMessage: "Overdue" },
  upcoming: { id: "Upcoming", defaultMessage: "Upcoming" },
  all: { id: "all", defaultMessage: "All" },
  gotPatientConsent: {
    id: "got_patient_consent",
    defaultMessage:
      "Have you got the patient's consent to add this family member",
  },
  relationToPatient: {
    id: "relation_to_patient",
    defaultMessage: "Relation to the Patient",
  },
  checkIfConsentFromPatient: {
    id: "check_if_consent_from_patient",
    defaultMessage:
      "Please check this if you have got consent from the patient",
  },
  toDeleteStaffNote: {
    id: "to_delete_staff_note",
    defaultMessage:
      "Are you sure you want to delete the staff note? It will be permanently deleted from the system and can not be reverted.",
  },
  toDeleteStatus: {
    id: "to_delete_status",
    defaultMessage:
      "Are you sure you want to delete the status message? It will be permanently deleted from the system and can not be reverted.",
  },
  sureToRemove: {
    id: "sure_to_remove",
    defaultMessage: "Are you sure you want to remove",
  },
  admin: {
    id: "admin",
    defaultMessage: "Admin",
  },
  noMembers: {
    id: "no_members",
    defaultMessage: "No members yet",
  },
  newStaffNote: {
    id: "new_staff_note",
    defaultMessage: "New Staff Note",
  },
  newNote: {
    id: "new_note",
    defaultMessage: "New Note",
  },
  newStatusMsg: {
    id: "new_status_msg",
    defaultMessage: "New status message",
  },

  confirmStaffNote: {
    id: "confirm_staff_note",
    defaultMessage: "Confirm adding Staff Note",
  },
  confirmNote: {
    id: "confirm_note",
    defaultMessage: "Confirm adding Note",
  },
  confirmStatus: {
    id: "confirm_status",
    defaultMessage: "Confirm Sending Message",
  },
  confirmUpdateStaffNoteQ: {
    id: "confirm_staff_note_q",
    defaultMessage: "Are you sure you want to update the Staff Note",
  },
  confirmUpdateNoteQ: {
    id: "confirm_note_q",
    defaultMessage: "Are you sure you want to update the Note",
  },
  confirmUpdateStatusQ: {
    id: "confirm_status_q",
    defaultMessage: "Are you sure you want to update the status message",
  },
  confirmUpdateStaffNote: {
    id: "confirm_upd_staff_note",
    defaultMessage: "Confirm updating Staff Note",
  },
  confirmUpdateStatus: {
    id: "confirm_upd_status",
    defaultMessage: "Confirm updating Status",
  },
  confirmUpdateNote: {
    id: "confirm_upd_note",
    defaultMessage: "Confirm updating Note",
  },
  by: {
    id: "by",
    defaultMessage: "by",
  },
  selectLibrary: {
    id: "select_library",
    defaultMessage: "Select Library",
  },
  selectConsentDocument: {
    id: "select_consent_document",
    defaultMessage: "Select Consent Document",
  },
  lastUpdatedOn: {
    id: "last_updated_on",
    defaultMessage: "Last Updated On",
  },
  assignedTo: {
    id: "assigned_to",
    defaultMessage: "Assigned to",
  },
  none: {
    id: "none",
    defaultMessage: "NONE",
  },
  reopen: {
    id: "reopen",
    defaultMessage: "Reopen",
  },
  reopenTask: {
    id: "reopenTask",
    defaultMessage: "Reopen Task",
  },
  sureToReopen: {
    id: "sure_to_reopen",
    defaultMessage: "Are you sure you want to reopen the task?",
  },
  reassignTask: {
    id: "reassign_task",
    defaultMessage: "Reassign Task",
  },
  editTask: {
    id: "edit_task",
    defaultMessage: "Edit Task",
  },
  taskAuditLogs: {
    id: "task_audit_logs",
    defaultMessage: "Task Audit Logs",
  },
  taskData: {
    id: "task_data",
    defaultMessage: "Task Data",
  },

  taskAccepted: {
    id: "task_accepted",
    defaultMessage: "Task has been accepted.",
  },
  taskAssigned: {
    id: "task_assigned",
    defaultMessage: "Task has been assigned to you.",
  },
  taskReassigned: {
    id: "task_reassigned",
    defaultMessage: "Task has been reassigned.",
  },
  taskMarkedComplete: {
    id: "task_marked_complete",
    defaultMessage: "Task has been marked complete.",
  },
  taskReopened: {
    id: "task_reopened",
    defaultMessage: "Task has been reopened.",
  },
  taskUpdated: {
    id: "task_updated",
    defaultMessage: "Task details have been updated.",
  },
  patientProfileDeactivated: {
    id: "patient_profile_deactivated",
    defaultMessage: "Patient profile has been de-activated.",
  },
  patientDeactivationProblems: {
    id: "patient_deactivation_problems",
    defaultMessage:
      "There were problems in de-activating the patient profile. Please try again later.",
  },
  patientRestored: {
    id: "patient_restored",
    defaultMessage: "Patient has been restored.",
  },
  natLoadPatient: {
    id: "nat_load_patient",
    defaultMessage: "Not able to load patient details. Please try again.",
  },
  inviteResent: {
    id: "invite_resent",
    defaultMessage: "Invite has been resent.",
  },
  credentialsReset: {
    id: "credentials_reset",
    defaultMessage: "Patient credentials have been reset.",
  },
  removedFromList: {
    id: "removed_from_list",
    defaultMessage: "Patient has been removed from your list.",
  },
  addedToList: {
    id: "added_to_list",
    defaultMessage: "Patient has been added to your list",
  },
  taskCreated: {
    id: "task_created",
    defaultMessage: "Task has been created",
  },
  memberRemoved: {
    id: "member_removed",
    defaultMessage: "Member has been removed",
  },
  memberAdded: {
    id: "member_added",
    defaultMessage: "Member has been added",
  },
  staffNotesUpdated: {
    id: "staff_notes_updated",
    defaultMessage: "Staff Note has been updated.",
  },
  noteUpdated: {
    id: "note_updated",
    defaultMessage: "Note has been updated.",
  },
  statusMessageUpdated: {
    id: "status_message_updated",
    defaultMessage: "Status Message has been updated.",
  },
  staffNotedDeleted: {
    id: "staff_noted_deleted",
    defaultMessage: "Staff Note has been deleted.",
  },
  statusMessageDeleted: {
    id: "status_message_deleted",
    defaultMessage: "Status Message has been deleted.",
  },
  profileUpdated: {
    id: "profileUpdated",
    defaultMessage: "Profile data has been updated.",
  },
  updateAttributeIntegerError: {
    id: "updateAttributeIntegerError",
    defaultMessage: "Entry Not valid (expected an Integer)",
  },
  document: {
    id: "document",
    defaultMessage: "Document",
  },
  documentAdded: {
    id: "document_added",
    defaultMessage: "Document has been added.",
  },
  documentDeleted: {
    id: "document_deleted",
    defaultMessage: "Document has been deleted.",
  },
  document_edited: {
    id: "document_edited",
    defaultMessage: "Document has been edited.",
  },
  fileUploadError: {
    defaultMessage:
      "There was a problem with file upload. Request might have timed out due to file size and slow network.",
    id: "file_upload_error",
  },
  patientAdded: {
    id: "patient_added",
    defaultMessage: "Patient {name} has been added.",
  },
  patientAddError: {
    id: "patient_add_error",
    defaultMessage: "Error while adding patient {name}. Please try again later",
  },
  staffNoteAdded: {
    id: "staff_note_added",
    defaultMessage: "Staff Note has been added.",
  },
  noteAdded: {
    id: "note_added",
    defaultMessage: "Your note has been added.",
  },
  statusAdded: {
    id: "status_added",
    defaultMessage: "Status message has been sent.",
  },
  addMemberError: {
    id: "add_member_error",
    defaultMessage: "Could not add member. Please try again.",
  },
  editStatusMessage: {
    id: "edit_status_message",
    defaultMessage: "Edit Status Message",
  },
  editStaffNote: {
    id: "edit_staff_note",
    defaultMessage: "Edit Staff Note",
  },
  editNote: {
    id: "edit_note",
    defaultMessage: "Edit Note",
  },
  createAppointment: {
    id: "create_appointment",
    defaultMessage: "New Booking",
  },
  ch_appointment_template: {
    id: "ch_appointment_template",
    defaultMessage: "Choose Appointment Template",
  },
  ch_appt_schedule: {
    id: "ch_appt_schedule",
    defaultMessage: "Choose Appointment Schedule",
  },
  appointment_slot: {
    id: "appointment_slot",
    defaultMessage: "Choose Appointment Slot",
  },
  appointment_details: {
    id: "appointment_details",
    defaultMessage: "Appointment Details",
  },

  patient_name: {
    id: "patient_name",
    defaultMessage: "Patient Name",
  },
  appointment_type: {
    id: "appointment_type",
    defaultMessage: "Appointment Type",
  },
  appointment_time: {
    id: "appointment_time",
    defaultMessage: "Appointment Time",
  },
  set_appointment_time: {
    id: "set_appointment_time",
    defaultMessage: "Please set valid Appointment date and time",
  },
  participants: {
    id: "participants",
    defaultMessage: "Participants",
  },
  appointment_desc: {
    id: "appointment_desc",
    defaultMessage: "Appointment Description",
  },
  set_appointment_desc: {
    id: "set_appointment_desc",
    defaultMessage: "Please set Appointment description",
  },
  comments: {
    id: "comments",
    defaultMessage: "Comments",
  },
  patient_instructions: {
    id: "patient_instructions",
    defaultMessage: "Patient Instructions",
  },

  medium: {
    id: "medium",
    defaultMessage: "Medium",
  },
  high: {
    id: "high",
    defaultMessage: "High",
  },
  appt_created: {
    id: "appt_created",
    defaultMessage: "Appointment has been created.",
  },
  slot_not_free: {
    id: "slot_not_free",
    defaultMessage: "Please select a free slot.",
  },
  changePatient: {
    id: "change_patient",
    defaultMessage: "Change Patient",
  },
  selectExistingPatient: {
    id: "select_existing_patient",
    defaultMessage: "Select Existing Patient",
  },
  create_without_schedule: {
    id: "create_without_schedule",
    defaultMessage: "Create Without Schedule",
  },
  view_schedules: {
    id: "view_schedules",
    defaultMessage: "View All Schedules",
  },
  no_template: {
    id: "no_template",
    defaultMessage: "No Template",
  },
  sendMessages: { id: "send_messages", defaultMessage: "Send Messages" },
  proposed: { id: "proposed", defaultMessage: "Proposed" },
  booked: { id: "booked", defaultMessage: "Booked" },
  fulfilled: { id: "fulfilled", defaultMessage: "Fulfilled" },
  cancelled: { id: "cancelled", defaultMessage: "Cancelled" },
  no_show: { id: "no_show", defaultMessage: "No Show" },
  rebooked: { id: "rebooked", defaultMessage: "Re-Booked" },
  appointment_status: {
    id: "appointment_status",
    defaultMessage: "Appointment Status",
  },
  appointmentUpdated: {
    id: "appointment_updated",
    defaultMessage: "Appointment has been updated.",
  },
  editAppointment: {
    id: "edit_appointment",
    defaultMessage: "Edit Appointment",
  },
  cancel_appointment: {
    id: "cancel_appointment",
    defaultMessage: "Cancel Appointment",
  },
  appointmentCancelled: {
    id: "appointment_cancelled",
    defaultMessage: "Appointment has been cancelled.",
  },
  toCancelAppointment: {
    id: "to_cancel_appointment",
    defaultMessage: "Are you sure you want to cancel the appointment?",
  },
  fa_patient: {
    id: "fa_patient",
    defaultMessage: "Patient",
  },
  fa_change: {
    id: "fa_change",
    defaultMessage: "Change",
  },
  fa_name: {
    id: "fa_name",
    defaultMessage: "Name",
  },
  fa_set_name: {
    id: "fa_set_name",
    defaultMessage: "Please set name",
  },
  fa_title: {
    id: "fa_title",
    defaultMessage: "Title",
  },
  fa_comments: {
    id: "fa_comments",
    defaultMessage: "Comments",
  },
  fa_setTitle: {
    id: "fa_setTitle",
    defaultMessage: "Please set title",
  },
  fa_description: {
    id: "fa_Description",
    defaultMessage: "Description",
  },
  fa_set_description: {
    id: "fa_set_description",
    defaultMessage: "Please set description",
  },
  fa_status_message: {
    id: "fa_status_message",
    defaultMessage: "Status Message",
  },
  fa_type: {
    id: "fa_type",
    defaultMessage: "Type",
  },
  fa_set_type: {
    id: "fa_set_type",
    defaultMessage: "Please set type",
  },
  fa_set_status: {
    id: "fa_set_status",
    defaultMessage: "Please set status message",
  },
  fa_survey: {
    id: "fa_survey",
    defaultMessage: "Survey",
  },
  fa_set_survey: {
    id: "fa_set_survey",
    defaultMessage: "Please set survey",
  },
  fa_status_template: {
    id: "fa_status_template",
    defaultMessage: "Status Template",
  },
  fa_set_status_template: {
    id: "fa_set_status_template",
    defaultMessage: "Please set status template",
  },
  fa_library: {
    id: "fa_library",
    defaultMessage: "Library",
  },
  fa_set_library: {
    id: "fa_set_library",
    defaultMessage: "Please set library",
  },
  fa_care_plan: {
    id: "fa_care_plan",
    defaultMessage: "Care Plan",
  },
  fa_set_care_plan: {
    id: "fa_set_care_plan",
    defaultMessage: "Please set care plan",
  },
  fa_ui_attribute: {
    id: "fa_ui_attribute",
    defaultMessage: "UI Attribute",
  },
  fa_set_ui_attribute: {
    id: "fa_set_ui_attribute",
    defaultMessage: "Please set UI attribute.",
  },
  fa_consent_document: {
    id: "fa_consent_document",
    defaultMessage: "Consent Document",
  },
  fa_set_consent_document: {
    id: "fa_set_consent_document",
    defaultMessage: "Please set consent document",
  },
  fa_task_template: {
    id: "fa_task_template",
    defaultMessage: "Task Template",
  },
  fa_set_task_template: {
    id: "fa_set_task_template",
    defaultMessage: "Please set task template",
  },
  fa_send_as_status: {
    id: "fa_send_as_status",
    defaultMessage: "Send As Status Message",
  },
  fa_select_survey: {
    id: "fa_select_survey",
    defaultMessage: "Select Survey",
  },
  fa_select_library: {
    id: "fa_select_library",
    defaultMessage: "Select Library",
  },
  fa_select_care_plan: {
    id: "fa_select_care_plan",
    defaultMessage: "Select Care Plan",
  },
  fa_select_consent: {
    id: "fa_select_consent",
    defaultMessage: "Select Consent Document",
  },
  fa_select_task: {
    id: "fa_select_task",
    defaultMessage: "Select Task",
  },
  fa_select_task_template: {
    id: "fa_select_task_template",
    defaultMessage: "Select Task Template",
  },
  fa_not_able_to_find: {
    id: "fa_not_able_to_find",
    defaultMessage: "Not able to find what you are looking for?",
  },
  fa_create_library: {
    id: "fa_create_library",
    defaultMessage: "Create a Library",
  },
  fa_instead: {
    id: "fa_instead",
    defaultMessage: "instead",
  },
  fa_create: {
    id: "fa_create",
    defaultMessage: "Create",
  },
  fa_appointment_schedule: {
    id: "fa_appointment_schedule",
    defaultMessage: "Appointment Schedule",
  },
  fa_new_appointment_schedule: {
    id: "fa_new_appointment_schedule",
    defaultMessage: "New Appointment Schedule",
  },
  fa_appointment_schedules: {
    id: "fa_appointment_schedules",
    defaultMessage: "Appointment Schedules",
  },
  fa_appointment_templates: {
    id: "fa_appointment_templates",
    defaultMessage: "Appointment Templates",
  },
  fa_patient_type: {
    id: "fa_patient_type",
    defaultMessage: "Patient Type",
  },
  fa_new_schedule: {
    id: "fa_new_schedule",
    defaultMessage: "New Schedule",
  },

  fa_set_participants: {
    id: "fa_set_participants",
    defaultMessage: "Please set participants",
  },

  fa_staff: {
    id: "fa_staff",
    defaultMessage: "Staff",
  },

  fa_remove: {
    id: "fa_remove",
    defaultMessage: "Remove",
  },
  fa_duration: {
    id: "fa_duration",
    defaultMessage: "Duration",
  },
  fa_set_duration: {
    id: "fa_set_duration",
    defaultMessage: "Please set duration",
  },
  fa_from: {
    id: "fa_from",
    defaultMessage: "From",
  },
  fa_to: {
    id: "fa_to",
    defaultMessage: "To",
  },
  fa_slot_interval: {
    id: "fa_slot_interval",
    defaultMessage: "Slot Duration",
  },
  fa_schedule: {
    id: "fa_schedule",
    defaultMessage: "Schedule",
  },
  fa_set_slot_interval: {
    id: "fa_set_slot_interval",
    defaultMessage: "Please set Slot Duration",
  },
  fa_appointment_slots: {
    id: "fa_appointment_slots",
    defaultMessage: "Appointment Slots",
  },
  fa_status: {
    id: "fa_status",
    defaultMessage: "Status",
  },
  fa_submit: {
    id: "fa_submit",
    defaultMessage: "Submit",
  },
  fa_schedule_created: {
    id: "fa_schedule_created",
    defaultMessage: "Appointment Schedule has been created.",
  },
  fa_schedule_edited: {
    id: "fa_schedule_edited",
    defaultMessage: "Appointment Schedule has been edited.",
  },
  fa_slot_updated: {
    id: "fa_slot_updated",
    defaultMessage: "Slot has been updated.",
  },
  fa_slot_deleted: {
    id: "fa_slot_deleted",
    defaultMessage: "Slot has been deleted.",
  },
  fa_delete_slot: {
    id: "fa_delete_slot",
    defaultMessage: "Delete Slot",
  },
  fa_delete_slots: {
    id: "fa_delete_slots",
    defaultMessage: "Delete Slots",
  },
  fa_delete_slots_message: {
    id: "fa_delete_slots_message",
    defaultMessage:
      "Are you sure you want to delete the slots? When no participants given, it will delete all the slots for the given date range.",
  },
  fa_delete_slot_message: {
    id: "fa_delete_slot_message",
    defaultMessage: "Are you sure you want to delete the Slot?",
  },
  fa_create_slots: {
    id: "fa_create_slots",
    defaultMessage: "Create Slots",
  },
  fa_repeat: {
    id: "fa_repeat",
    defaultMessage: "Repeat",
  },
  fa_set_from_to_date: {
    id: "fa_set_from_to_date",
    defaultMessage: "Please set from and to date",
  },
  fa_set_day_repeat: {
    id: "fa_set_day_repeat",
    defaultMessage: "Please set the day",
  },
  fa_edit_slot: {
    id: "fa_edit_slot",
    defaultMessage: "Edit Slot",
  },
  mins: {
    id: "mins",
    defaultMessage: "Mins",
  },
  fa_slot_interval_15: {
    id: "fa_slot_interval_15",
    defaultMessage: "15 Mins",
  },
  fa_slot_interval_20: {
    id: "fa_slot_interval_20",
    defaultMessage: "20 Mins",
  },
  fa_slot_interval_30: {
    id: "fa_slot_interval_30",
    defaultMessage: "30 Mins",
  },
  fa_slot_interval_45: {
    id: "fa_slot_interval_45",
    defaultMessage: "45 Mins",
  },
  fa_slot_interval_60: {
    id: "fa_slot_interval_60",
    defaultMessage: "1 Hour",
  },
  fa_slot_interval_90: {
    id: "fa_slot_interval_90",
    defaultMessage: "1 Hour and 30 Mins",
  },
  fa_slot_interval_120: {
    id: "fa_slot_interval_120",
    defaultMessage: "2 Hours",
  },
  fa_slot_interval_150: {
    id: "fa_slot_interval_150",
    defaultMessage: "2 Hours and 30 Mins",
  },
  fa_slot_interval_180: {
    id: "fa_slot_interval_180",
    defaultMessage: "3 Hours",
  },
  fa_slot_repeat_all: {
    id: "fa_slot_repeat_all",
    defaultMessage: "All Days",
  },
  fa_slot_repeat_week: {
    id: "fa_slot_repeat_week",
    defaultMessage: "Every Monday, Tuesday, Wednesday, Thursday and Friday",
  },
  fa_slot_repeat_mwf: {
    id: "fa_slot_repeat_mwf",
    defaultMessage: "Every Monday, Wednesday and Friday",
  },
  fa_slot_repeat_tt: {
    id: "fa_slot_repeat_tt",
    defaultMessage: "Every Tuesday and Thursday",
  },
  fa_slot_repeat_mon: {
    id: "fa_slot_repeat_mon",
    defaultMessage: "Every Monday",
  },
  fa_slot_repeat_tue: {
    id: "fa_slot_repeat_tue",
    defaultMessage: "Every Tuesday",
  },
  fa_slot_repeat_wed: {
    id: "fa_slot_repeat_wed",
    defaultMessage: "Every Wednesday",
  },
  fa_slot_repeat_thu: {
    id: "fa_slot_repeat_thu",
    defaultMessage: "Every Thursday",
  },
  fa_slot_repeat_fri: {
    id: "fa_slot_repeat_fri",
    defaultMessage: "Every Friday",
  },
  fa_slot_repeat_sat: {
    id: "fa_slot_repeat_sat",
    defaultMessage: "Every Saturday",
  },
  fa_slot_repeat_sun: {
    id: "fa_slot_repeat_sun",
    defaultMessage: "Every Sunday",
  },
  fa_slot_status_busy: {
    id: "fa_slot_status_busy",
    defaultMessage: "Busy",
  },
  fa_slot_status_free: {
    id: "fa_slot_status_free",
    defaultMessage: "Free",
  },
  fa_slot_status_busyua: {
    id: "fa_slot_status_busyua",
    defaultMessage: "Busy Unavailable",
  },
  fa_slot_status_busytt: {
    id: "fa_slot_status_busytt",
    defaultMessage: "Busy Tentative",
  },
  fa_slot_status_error: {
    id: "fa_slot_status_error",
    defaultMessage: "Entered In Error",
  },
  fa_appt_template: {
    id: "fa_appt_template",
    defaultMessage: "Appointment Template",
  },
  fa_edit_appt_template: {
    id: "fa_edit_appt_template",
    defaultMessage: "Edit Appointment Template",
  },
  fa_new_appt_template: {
    id: "fa_new_appt_template",
    defaultMessage: "New Appointment Template",
  },
  fa_template_created: {
    id: "fa_template_created",
    defaultMessage: "Appointment Template has been created.",
  },
  fa_template_edited: {
    id: "fa_template_edited",
    defaultMessage: "Appointment Template has been edited.",
  },

  fa_appointment_desc: {
    id: "fa_appointment_desc",
    defaultMessage: "Appointment Description",
  },

  fa_patient_instructions: {
    id: "fa_patient_instructions",
    defaultMessage: "Patient Instructions",
  },
  fa_low: {
    id: "fa_low",
    defaultMessage: "Low",
  },
  fa_medium: {
    id: "fa_medium",
    defaultMessage: "Medium",
  },
  fa_high: {
    id: "fa_high",
    defaultMessage: "High",
  },
  fa_priority: { id: "fa_priority", defaultMessage: "Priority" },
  fa_patient_types: {
    id: "fa_patient_types",
    defaultMessage: "Patient Types",
  },
  fa_appointment_type: {
    id: "fa_appointment_type",
    defaultMessage: "Appointment Type",
  },
  fa_visibility: {
    id: "fa_visibility",
    defaultMessage: "Visibility",
  },
  fa_confirmation_email_sub: {
    id: "fa_confirmation_email_sub",
    defaultMessage: "Confirmation Email Subject",
  },
  fa_confirmation_email_content: {
    id: "fa_confirmation_email_content",
    defaultMessage: "Confirmation Email Content",
  },
  fa_confirmation_sms_content: {
    id: "fa_confirmation_sms_content",
    defaultMessage: "Confirmation SMS Content",
  },

  fa_confirmation_messages: {
    id: "fa_confirmation_messages",
    defaultMessage: " Confirmation Messages",
  },
  fa_reminders_config: {
    id: "fa_reminders_config",
    defaultMessage: "Reminders Configuration",
  },
  fa_slots_created: {
    id: "fa_slots_created",
    defaultMessage: "Slots have been created.",
  },
  fa_edit: {
    id: "fa_edit",
    defaultMessage: "Edit",
  },
  fa_template_deleted: {
    id: "fa_template_deleted",
    defaultMessage: "Template has been deleted.",
  },
  fa_delete_template_error: {
    id: "fa_delete_template_error",
    defaultMessage:
      "Error while deleting the template. Please try again later.",
  },

  fa_delete_template: {
    id: "fa_delete_template",
    defaultMessage: "Delete Template",
  },
  to_delete_template: {
    id: "to_delete_template",
    defaultMessage:
      "Are you sure you want to delete the appointment template? It will be permanently deleted from the system and can not be reverted.",
  },
  fa_delete_schedule: {
    id: "fa_delete_schedule",
    defaultMessage: "Delete Schedule",
  },
  fa_delete_schedule_message: {
    id: "fa_delete_schedule_message",
    defaultMessage: "Are you sure you wan to delete the Schedule?",
  },
  fa_schedule_deleted: {
    id: "fa_schedule_deleted",
    defaultMessage: "Appointment Schedule has been deleted.",
  },
  active: {
    id: "active",
    defaultMessage: "Active",
  },
  active_uc: {
    id: "active_uc",
    defaultMessage: "ACTIVE",
  },
  inactive: {
    id: "inactive",
    defaultMessage: "Inactive",
  },
  set_appointment_description: {
    id: "set_appointment_description",
    defaultMessage: "Please set Appointment description",
  },
  set_appointment_type: {
    id: "set_appointment_type",
    defaultMessage: "Please set Appointment type",
  },
  invitation_expired_uc: {
    id: "invitation_expired_uc",
    defaultMessage: "INVITATION EXPIRED",
  },
  active__internal_uc: {
    id: "active__internal_uc",
    defaultMessage: "ACTIVE INTERNAL",
  },
  pending_invitation_uc: {
    id: "pending_invitation_uc",
    defaultMessage: "PENDING INVITATION",
  },
  invite_sent_date: {
    id: "invite_sent_date",
    defaultMessage: "Sent on {date}",
  },
  mrn_w_value: {
    id: "mrn_w_value",
    defaultMessage: "MRN: {mrnNumber}",
  },
  attention_required_uc: {
    id: "attention_required_uc",
    defaultMessage: "ATTENTION REQUIRED",
  },
  monitor_uc: {
    id: "monitor_uc",
    defaultMessage: "MONITOR",
  },
  on_track_uc: {
    id: "on_track_uc",
    defaultMessage: "ON TRACK",
  },
  multi_group_column: {
    id: "multi_group_column",
    defaultMessage: " Multi Group Column",
  },
  multi_group_value_column: {
    id: "multi_group_value_column",
    defaultMessage: " Multi Group Value Column",
  },
  use_free_text: {
    id: "use_free_text",
    defaultMessage: "Use freetext",
  },
  medication_name: {
    id: "medication_name",
    defaultMessage: "Drug Name",
  },
  medication_type: {
    id: "medication_type",
    defaultMessage: "Route",
  },
  not_live_chat: {
    id: "not_live_chat",
    defaultMessage: "Not a live chat",
  },
  enter_message: {
    id: "enter_message",
    defaultMessage: "Enter message",
  },
  new_messages: {
    id: "new_messages",
    defaultMessage: "New messages",
  },
  adherence_report: {
    id: "adherence_report",
    defaultMessage: "Adherence Report",
  },
  hosp_no: {
    id: "hosp_no",
    defaultMessage: "Hosp No.",
  },
  no_days_on_treatment: {
    id: "no_days_on_treatment",
    defaultMessage: "No. Days On Treatment",
  },
  traffic_light_category: {
    id: "traffic_light_category",
    defaultMessage: "Traffic Light Category",
  },
  no_contacts_with_patients: {
    id: "no_contacts_with_patients",
    defaultMessage: "No. Contacts With Patients",
  },
  last_contact_attempt_date: {
    id: "last_contact_attempt_date",
    defaultMessage: "Last Contact Attempt Date",
  },
  hospital: {
    id: "hospital",
    defaultMessage: "Hospital",
  },
  episodes_of_care: {
    id: "episodes-of-care",
    defaultMessage: "EPISODES OF CARE",
  },
  choose_option: {
    id: "choose_option",
    defaultMessage: "Choose an option",
  },
  allow_reply: {
    id: "allow_reply",
    defaultMessage: "Allow Reply?",
  },
  reported: {
    id: "reported",
    defaultMessage: "Reported:",
  },
  test_name: {
    id: "test_name",
    defaultMessage: "Test Name",
  },
  result: {
    id: "result",
    defaultMessage: "Result",
  },
  unit: {
    id: "unit",
    defaultMessage: "Unit",
  },
  reference: {
    id: "reference",
    defaultMessage: "Reference",
  },
  comment: {
    id: "comment",
    defaultMessage: "Comment",
  },
  import_medications: {
    id: "import_medications",
    defaultMessage: "Import Medications",
  },
  import_surveys: {
    id: "import_surveys",
    defaultMessage: "Import Surveys",
  },
  video_call_options: {
    id: "video_call_options",
    defaultMessage: "Start now or schedule for later?",
  },
  video_call_now: {
    id: "video_call_now",
    defaultMessage: "Start video call now?",
  },
  chart: {
    id: "chart",
    dafaultMessage: "Chart",
  },
  last_result: {
    id: "last_result",
    defaultMessage: "Last Result",
  },
});

export default Messages;
