import React, { PureComponent } from "react";
import QuestionChoice from "./QuestionChoice";
import QuestionString from "./QuestionString";
import QuestionInteger from "./QuestionInteger";
import QuestionBoolean from "./QuestionBoolean";
import QuestionChoiceWithScale from "./QuestionChoiceWithScale";
import QuestionChoiceWithImage from "./QuestionChoiceWithImage";
import QuestionDate from "./QuestionDate";
import Mandatory from "../../../components/Mandatory";
import QuestionDecimal from "./QuestionDecimal";
import QuestionLocation from "./QuestionLocation";
import QuestionDateTime from "./QuestionDateTime";
import QuestionInformation from "./QuestionInformation";
import QuestionHumanoid from "./QuestionHumanoid";
import QuestionTsString from "./QuestionTsString";
import QuestionTsInteger from "./QuestionTsInteger";
import QuestionTsDouble from "./QuestionTsDouble";

function getComponent(type) {
  if (type === "CHOICE") {
    return QuestionChoice;
  } else if (type === "STRING") {
    return QuestionString;
  } else if (type === "INTEGER") {
    return QuestionInteger;
  } else if (type === "BOOLEAN") {
    return QuestionBoolean;
  } else if (type === "CHOICE_WITH_SCALE") {
    return QuestionChoiceWithScale;
  } else if (type === "CHOICE_WITH_IMAGES") {
    return QuestionChoiceWithImage;
  } else if (type === "DATE") {
    return QuestionDate;
  } else if (type === "DATE_TIME") {
    return QuestionDateTime;
  } else if (type === "DECIMAL") {
    return QuestionDecimal;
  } else if (type === "LOCATION") {
    return QuestionLocation;
  } else if (type === "DISPLAY") {
    return QuestionInformation;
  } else if (type === "HUMANOID") {
    return QuestionHumanoid;
  } else if (type === "TIME_SERIES_DOUBLE") {
    return QuestionTsDouble;
  } else if (type === "TIME_SERIES_INTEGER") {
    return QuestionTsInteger;
  } else if (type === "TIME_SERIES_STRING") {
    return QuestionTsString;
  }

  return null;
}

class SurveyGroupView extends PureComponent {
  render() {
    let { group, gIndex, setData, embedded, validateQuestions } = this.props;

    return (
      <div className="mb-2">
        <div
          className="card"
          style={{
            // borderTop: "2px solid #63c2de",
            border: "1px solid #f1f1f1",
          }}
        >
          {group && group.text && (
            <div
              className={
                embedded
                  ? "card-header survey-group-card-header py-1 px-3"
                  : "card-header survey-group-card-header py-1 px-3"
              }
            >
              {group.text}
            </div>
          )}

          <div
            className={embedded ? "card-body py-1 px-3" : "card-body py-1 px-3"}
          >
            {group &&
              group.items &&
              group.items.map((question, qIndex) => {
                let Component = getComponent(question.type);
                if (!Component) {
                  return null;
                }

                let oneRow =
                  question.type === "CHOICE" &&
                  question.options &&
                  question.options.length <= 2 &&
                  !question.options.some(
                    (option) =>
                      option.valueString && option.valueString.length > 12
                  );

                return (
                  <div className="mb-1" key={qIndex}>
                    {oneRow ? (
                      <div className="d-flex flex-row survey-group-row">
                        <div className="survey-group-question">
                          {question.text}{" "}
                          <Mandatory mandatory={question.required} />
                        </div>
                        <div className={"pr-3 mr-3"}>
                          <div
                            className="d-flex"
                            style={{ fontWeight: "bold" }}
                          >
                            <Component
                              question={question}
                              setData={(value) => {
                                setData(gIndex, qIndex, value);
                              }}
                              validateQuestions={validateQuestions}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex flex-column">
                        {question.type === "CHOICE" ? (
                          <>
                            <div className="survey-group-question">
                              {question.text}{" "}
                              <Mandatory mandatory={question.required} />
                            </div>
                            <div className="mt-2 mb-1">
                              <div className="row">
                                <div className="col-md-12">
                                  <Component
                                    question={question}
                                    setData={(value) => {
                                      setData(gIndex, qIndex, value);
                                    }}
                                    validateQuestions={validateQuestions}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div
                            className={`d-flex mt-2 mb-1 ${
                              question.type === "DISPLAY" ? "" : "w-100"
                            }`}
                            style={{
                              alignItems: "center",
                              ...(question.type === "DISPLAY" && {
                                flexDirection: "column",
                              }), // Stack content vertically for DISPLAY type
                            }}
                          >
                            <div
                              className="survey-group-question"
                              style={{
                                flexBasis:
                                  question.type === "DISPLAY" ? "100%" : "30%",
                                flexShrink: 0,
                                textAlign:
                                  question.type === "DISPLAY"
                                    ? "left"
                                    : undefined,
                              }}
                            >
                              {question.text}{" "}
                              <Mandatory mandatory={question.required} />
                            </div>

                            {question.type !== "DISPLAY" && (
                              <div style={{ flexBasis: "70%", flexShrink: 0 }}>
                                <Component
                                  question={question}
                                  setData={(value) => {
                                    setData(gIndex, qIndex, value);
                                  }}
                                  validateQuestions={validateQuestions}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default SurveyGroupView;
